// src/store/language/languageSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LanguageState {
    languages: { [key: string]: boolean };
    defaultLanguage: string;
    selectedLanguage: string;
    languagesOrder: { [key: string]: boolean };
}

const initialState: LanguageState = {
    languages: {
        'en': true,
        'sr': true,
        'es': true,
        'ru': true,
        'fr': true,
    },
    defaultLanguage: 'sr',
    selectedLanguage: 'sr',

    languagesOrder: {
        'en': true,
        'sr': true,
        'es': true,
        'ru': true,
        'fr': true,
    },
};


export const languageSlice = createSlice({
    name: 'language',
    initialState,
    reducers: {
        setLanguageCheckedStatus: (state, action: PayloadAction<{ language: string, checked: boolean }>) => {
            const { language, checked } = action.payload;
            state.languages[language] = checked;
        },
        setDefaultLanguage: (state, action: PayloadAction<string>) => {
            const newDefaultLanguage = action.payload;
            const newLanguagesOrder = { [newDefaultLanguage]: true };

            // Dodajte ostale jezike čuvajući njihov trenutni status
            Object.keys(state.languages).forEach(lang => {
                if (lang !== newDefaultLanguage) {
                    newLanguagesOrder[lang] = state.languages[lang];
                }
            });

            state.defaultLanguage = newDefaultLanguage;
            state.languagesOrder = newLanguagesOrder;
        },

        setSelectedLanguage: (state, action: PayloadAction<string>) => {
            const newSelectedLanguage = action.payload;
            state.selectedLanguage = newSelectedLanguage;
        }

    },
});

export const { setLanguageCheckedStatus, setDefaultLanguage, setSelectedLanguage } = languageSlice.actions;

export default languageSlice.reducer;
