import React, { useEffect, useState } from 'react';
import { Table, TableCell, TableBody, TableHead, TableRow, Button, Input, CheckboxField, RadioGroupField, Radio } from '@aws-amplify/ui-react';
import { useTranslation } from 'react-i18next';
import { setPrimaryButtonColor, setPrimaryFontColor, setBackgrounColor, setSidebarBackgroundColor, setSecondaryFontColor } from '../../store/theme/themeSlice';
import { setLogoUrl } from '../../store/theme/logoSlice';
import { setLanguageCheckedStatus, setDefaultLanguage } from '../../store/language/languageSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';


const Theme = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const primaryButtonColor = useSelector((state: RootState) => state.theme.colors.primaryButton);
    const primaryFontColor = useSelector((state: RootState) => state.theme.colors.primaryFont);
    const backgroundColor = useSelector((state: RootState) => state.theme.colors.backgroundColor);
    const logoUrl = useSelector((state: RootState) => state.logo.url);
    const sidebarBackgroundColor = useSelector((state: RootState) => state.theme.colors.sidebarBackgroundColor);
    const secondaryFontColor = useSelector((state: RootState) => state.theme.colors.secondaryFont);
    const languages = useSelector((state: RootState) => state.language.languages);
    const defaultLanguage = useSelector((state: RootState) => state.language.defaultLanguage);
    const [colorInput, setColorInput] = useState(primaryButtonColor);
    const [fontColorInput, setFontColorInput] = useState(primaryFontColor);
    const [backgroundInput, setBackgroundInput] = useState(backgroundColor);
    const [sidebarBackgroundColorInput, setSidebarBackgroundColorInput] = useState(sidebarBackgroundColor);
    const [secondaryFontColorInput, setSecondaryFontColorInput] = useState(secondaryFontColor);

    const handleLanguageChange = (language: string, isChecked: boolean) => {
        dispatch(setLanguageCheckedStatus({ language, checked: isChecked }));
    };

    const handleDefaultLanguageChange = (lang: any) => {
        console.log(lang);
        dispatch(setDefaultLanguage(lang));
    }


    useEffect(() => {
        setColorInput(primaryButtonColor);
        setFontColorInput(primaryFontColor);
        setBackgroundInput(backgroundColor);
        setLogoUrl(logoUrl);
        setSidebarBackgroundColorInput(sidebarBackgroundColor);
        setSecondaryFontColorInput(secondaryFontColor);
    }, [primaryButtonColor, primaryFontColor, backgroundColor, logoUrl, sidebarBackgroundColor, languages, dispatch, defaultLanguage, secondaryFontColor]);

    const handleColorChange = (setter) => (e) => {
        setter(e.target.value);
    };

    const handleLogoChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            const result = reader.result as string;
            dispatch(setLogoUrl(result));
        };
    }

    // Dispatch the color changes
    const handleColorDispatch = (colorType, color) => {
        switch (colorType) {
            case 'primaryButton':
                dispatch(setPrimaryButtonColor(color));
                break;
            case 'primaryFont':
                dispatch(setPrimaryFontColor(color));
                break;
            case 'background':
                dispatch(setBackgrounColor(color));
                break;
            case 'sidebarBackground':
                dispatch(setSidebarBackgroundColor(color));
                break;
        }
    };

    const colorSettings = [
        {
            label: 'background_color',
            color: backgroundInput,
            setColor: setBackgroundInput,
            dispatchType: 'background'
        },
        {
            label: 'sidebar_background_color',
            color: sidebarBackgroundColorInput,
            setColor: setSidebarBackgroundColorInput,
            dispatchType: 'sidebarBackground'
        },
        {
            label: 'primary_button_color',
            color: colorInput,
            setColor: setColorInput,
            dispatchType: 'primaryButton'
        },
        {
            label: 'primary_font_color',
            color: fontColorInput,
            setColor: setFontColorInput,
            dispatchType: 'primaryFont'
        },

        {
            label: 'secondary_font_color',
            color: secondaryFontColorInput,
            setColor: setSecondaryFontColorInput,
            dispatchType: 'secondaryFont'
        },
    ];

    return (
        <>
            <h2>{t('theme_menu')}</h2>
            <Table caption="" highlightOnHover={false}>
                <TableHead>
                    <TableRow>
                        <TableCell as="th">{t('setting')}</TableCell>
                        <TableCell as="th">{t('value')}</TableCell>
                        <TableCell as="th">{t('action')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>{t('logo')}</TableCell>
                        <TableCell style={{ display: 'flex', alignItems: 'center' }}>
                            <label htmlFor="file-upload" className="custom-file-upload">{t('upload_logo')}</label>
                            <Input id="file-upload" type="file" accept="image/*" onChange={handleLogoChange} style={{ display: 'none' }} />

                            <img
                                src={logoUrl}
                                alt="Logo Preview"
                                style={{ maxWidth: '100px', maxHeight: '100px', padding: '10px', border: '0.5px solid gray', marginLeft: '40px' }}
                            />
                        </TableCell>
                        <TableCell>
                            <Button
                            >{t('save')}</Button>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>{t('default_language')}</TableCell>
                        <TableCell>
                            <RadioGroupField
                                label=""
                                name="defaultLanguage"
                                value={defaultLanguage}
                                onChange={(e) => handleDefaultLanguageChange(e.target.value)}
                            >
                                {Object.entries(languages).map(([lang, isChecked]) => (
                                    <div key={lang} className="radio-option">
                                        <Radio
                                            id={`default-lang-${lang}`}
                                            value={lang}
                                            checked={defaultLanguage === lang}
                                            onChange={(e) => handleDefaultLanguageChange(e.target.value)}
                                        >


                                            {t(`language_${lang}`)}
                                        </Radio>

                                    </div>
                                ))}
                            </RadioGroupField>


                        </TableCell>
                        <TableCell>
                            <Button>{t('save')}</Button>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>{t('languages')}</TableCell>
                        <TableCell>
                            {Object.entries(languages).map(([lang, isChecked]) => (
                                <CheckboxField
                                    key={lang}
                                    name="languages"
                                    label={t(`language_${lang}`)}
                                    value={lang}
                                    checked={isChecked}
                                    onChange={(e) => handleLanguageChange(lang, e.target.checked)}
                                />
                            ))}

                        </TableCell>
                        <TableCell>
                            <Button>{t('save')}</Button>
                        </TableCell>
                    </TableRow>
                    {colorSettings.map((setting, index) => (
                        <TableRow key={index}>
                            <TableCell>{t(setting.label)}</TableCell>
                            <TableCell>
                                <Input
                                    style={{ width: '25%', height: '70px', padding: '0' }}
                                    type="color"
                                    value={setting.color}
                                    onChange={handleColorChange(setting.setColor)}
                                    onBlur={() => handleColorDispatch(setting.dispatchType, setting.color)}
                                />
                            </TableCell>
                            <TableCell>
                                <Button onClick={() => handleColorDispatch(setting.dispatchType, setting.color)}>
                                    {t('save')}
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );
};

export default Theme;

