/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateTodo = /* GraphQL */ `
  subscription OnCreateTodo($filter: ModelSubscriptionTodoFilterInput) {
    onCreateTodo(filter: $filter) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTodo = /* GraphQL */ `
  subscription OnUpdateTodo($filter: ModelSubscriptionTodoFilterInput) {
    onUpdateTodo(filter: $filter) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTodo = /* GraphQL */ `
  subscription OnDeleteTodo($filter: ModelSubscriptionTodoFilterInput) {
    onDeleteTodo(filter: $filter) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const onCreateProgressPayments = /* GraphQL */ `
  subscription OnCreateProgressPayments(
    $filter: ModelSubscriptionProgressPaymentsFilterInput
  ) {
    onCreateProgressPayments(filter: $filter) {
      id
      userID
      subscriptionType
      startDate
      endDate
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateProgressPayments = /* GraphQL */ `
  subscription OnUpdateProgressPayments(
    $filter: ModelSubscriptionProgressPaymentsFilterInput
  ) {
    onUpdateProgressPayments(filter: $filter) {
      id
      userID
      subscriptionType
      startDate
      endDate
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteProgressPayments = /* GraphQL */ `
  subscription OnDeleteProgressPayments(
    $filter: ModelSubscriptionProgressPaymentsFilterInput
  ) {
    onDeleteProgressPayments(filter: $filter) {
      id
      userID
      subscriptionType
      startDate
      endDate
      isActive
      createdAt
      updatedAt
    }
  }
`;

export const onCreateProgressUserHistory = /* GraphQL */ `
  subscription OnCreateProgressUserHistory(
    $filter: ModelSubscriptionProgressUserHistoryFilterInput
  ) {
    onCreateProgressUserHistory(filter: $filter) {
      id
      userID
      adminGroupName
      lastVisited

    }
  }
`;

export const onUpdateProgressUserHistory = /* GraphQL */ `
  subscription OnUpdateProgressUserHistory(
    $filter: ModelSubscriptionProgressUserHistoryFilterInput
  ) {
    onUpdateProgressUserHistory(filter: $filter) {
      id
      userID
      adminGroupName
      lastVisited
    }
  }
`;


