import { useSelector } from 'react-redux';
import { Theme } from "@aws-amplify/ui-react";
import { RootState } from '../src/store/store';


const useTheme = () => {
  const themeColors = useSelector((state: RootState) => state.theme.colors);

  const theme: Theme = {
    name: "my-theme",
    tokens: {
      colors: {
        primaryButton: { value: themeColors.primaryButton },
        primaryButtonHover: { value: "#573CDF" },
        secondaryButton: { value: themeColors.secondaryButton },
        secondaryButtonHover: { value: "#FFFFFF" },
        inputColor: { value: "#232323" },
        selectColor: { value: "#232323" },
        backgroundColor: { value: themeColors.backgroundColor },
        amountColor: { value: "#FFFFFF" },
        greyColor: { value: "#121212" },
        purpleColor: { value: "#7C66EB" },
        sidebarBackgroundColor: { value: themeColors.sidebarBackgroundColor },
        font: {
          primary: { value: themeColors.primaryFont },
          secondary: { value: themeColors.secondaryFont },
        },
        overlay: {
          50: { value: "rgba(0, 0, 0, 0.5)" },
        },
        background: {
          primary: { value: themeColors.backgroundColor },
        }
      },
      components: {
        heading: {
          color: { value: "#FFFFFF" },
        },
        authenticator: {
          router: {
            backgroundColor: { value: "#121212" },
            borderWidth: { value: "0px" },
            boxShadow: { value: "0px 3px 11px 0px #00000040" },
          },
        },
        button: {
          backgroundColor: { value: themeColors.primaryButton },
          color: { value: "#FFFFFF" },
          _hover: {
            backgroundColor: { value: "#573CDF" },
            color: { value: "#FFFFFF" },
          },
          _active: {
            backgroundColor: { value: "#573CDF" },
            color: { value: "#FFFFFF" },
          },
          _focus: {
            backgroundColor: { value: "#573CDF" },
            boxShadow: { value: "none" },
            color: { value: "#FFFFFF" },
          },
          primary: {
            backgroundColor: { value: "#7C66EB" },
            _hover: {
              backgroundColor: { value: "#573CDF" },
            },
            _active: {
              backgroundColor: { value: "#573CDF" },
            },
            _focus: {
              backgroundColor: { value: "#573CDF" },
            },
          },
          link: {
            color: { value: "#7C66EB" },
            _hover: {
              color: { value: "#573CDF" },
              backgroundColor: { value: "transparent" },
            },
            _active: {
              color: { value: "#573CDF" },
              backgroundColor: { value: "transparent" },
            },
            _focus: {
              color: { value: "#573CDF" },
              backgroundColor: { value: "transparent" },
              boxShadow: { value: "none" },
            },
          },
        },
        table: {
          data: {
            color: "#D9D9D9",
            borderColor: "#D9D9D9",
          },
          header: {
            color: "#FFFFFF",
            borderColor: "#D9D9D9",
          }
        },
        card: {
          backgroundColor: "#121212",
          boxShadow: "0px 3px 11px 0px #00000040",
        },
        text: {
          color: { value: themeColors.secondaryFont },
        },
        fieldcontrol: {
          borderStyle: "none",
          color: "#FFFFFF"
        },
        input: {
          color: "#FFFFFF"
        }
      }
    },
  }
  return theme;
  ;
}

export default useTheme;
