import React, { useState } from "react"; // Import useState
import "./SideBar.css";
import { NavLink, useNavigate } from "react-router-dom";
import { Icon } from "@aws-amplify/ui-react";
import { Auth } from 'aws-amplify';
import ConfirmDialog from "../../pages/dialog/ConfirmDialog";
import { useTranslation } from "react-i18next";

export interface NavItemData {
  eventKey?: string;
  title?: string;
  icon?: any;
  to?: any;
  target?: string;
  children?: NavItemData[];
}

export interface SidebarNavProps {
  navs?: NavItemData[];
}

const SideBarNav = (props: SidebarNavProps) => {
  const { navs } = props;
  const navigate = useNavigate();
  const [isDialogOpen, setDialogOpen] = useState(false); // State to control dialog visibility
  const { t } = useTranslation();

  let activeClassName = "active";

  const openDialog = () => { // Function to open the dialog
    setDialogOpen(true);
  };

  const handleLogout = async () => { // Function to handle logout
    try {
      await Auth.signOut();
      navigate('/'); // Redirect to home or login page
      setDialogOpen(false); // Close the dialog
    } catch (error) {
      console.log('Error signing out: ', error);
    }
  };

  return (
    <>
      <ConfirmDialog isOpen={isDialogOpen} handleClose={() => setDialogOpen(false)} handleLogout={handleLogout} />
      <div className="sidebar-nav">
        <ul>
          {navs?.map((item) => {
            const { children, ...rest } = item;

            if (children) {
              return (
                <li key={item.eventKey}>
                  <NavLink
                    to={item.to}
                    className={({ isActive }) => isActive ? activeClassName : undefined}
                  >
                    <Icon>{item.icon}</Icon>
                    {/* {item.title} */}
                    {item.eventKey !== undefined ? t(item.eventKey + '_menu') : t('')}
                  </NavLink>
                  {/* <ul>
                    {children.map((child) => {
                      return (
                        <li key={child.eventKey}>
                          <NavLink
                            to={child.to}
                            className={({ isActive }) => isActive ? activeClassName : undefined}
                          >
                            {child.title}
                          </NavLink>
                        </li>
                      );
                    })}
                  </ul> */}
                </li>
              );
            }

            if (rest.target === "_blank") {
              return <></>;
            }

            if (item.eventKey === "logout") {
              return (
                <li key={item.eventKey} onClick={openDialog}>
                  <NavLink
                    to={item.to}
                    className={"logout"}
                  >
                    <Icon>{item.icon}</Icon>
                    {/* {item.title} */}
                    {item.eventKey !== undefined ? t(item.eventKey + '_menu') : t('')}
                  </NavLink>
                </li>
              );
            }

            return (
              <li key={item.eventKey}>
                <NavLink
                  to={item.to}
                  className={({ isActive }) => isActive ? activeClassName : undefined}
                >
                  <Icon>{item.icon}</Icon>
                  {/* {item.title} */}
                  {item.eventKey !== undefined ? t(item.eventKey + '_menu') : t('')}
                </NavLink>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default SideBarNav;
