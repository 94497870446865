/* eslint-disable */
// this is an auto generated file. This will be overwritten
export const createAndSaveBarcode = `
  query CreateAndSaveBarcode($input: String!) {
    createAndSaveBarcode(input: $input)
  }
`;

export const createUser = `
  query CreateUser($input: CreateUserInput!) {
    createUser(input: $input)
  }
`;

export const getUser = `
  query GetUser($input: String!) {
    getUser(input: $input) {
      Username
      Name
      Surname
      Email
      PhoneNumber
      CreatedAt
    }
  }
`;

export const listNonAdminUsers = `
query ListNonAdminUsers {
  listNonAdminUsers {
      Username
      Name
      Surname
      Email
      PhoneNumber
      CreatedAt
  }
}
`;


export const getTodo = /* GraphQL */ `
  query GetTodo($id: ID!) {
    getTodo(id: $id) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;

export const getProgressPayments = /* GraphQL */ `
  query GetProgressPayments($id: ID!) {
    getProgressPayments(id: $id) {
      id
      userID
      subscriptionType
      startDate
      endDate
      isActive
      createdAt
      updatedAt
    }
  }
`;

export const listProgressPayments = /* GraphQL */ `
  query ListProgressPayments(
    $filter: ModelProgressPaymentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProgressPayments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        subscriptionType
        startDate
        endDate
        isActive
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listProgressPaymentsByUser = /* GraphQL */ `
  query ListProgressPaymentsByUser(
    $filter: ModelProgressPaymentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProgressPayments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        subscriptionType
        startDate
        endDate
        isActive
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;


export const getProgressPaymentsByUserId = `
  query GetProgressPaymentsByUserId($userID: String!) {
    progressPaymentsByUserId(userID: $userID,
      sortDirection: DESC) {
      items {
        id
        userID
        subscriptionType
        startDate
        endDate
        isActive
        expireAt
      }
      nextToken
    }
  }
`;

export const getAppSettings = /* GraphQL */ `
  query GetAppSettings($id: ID!) {
    getAppSettings(id: $id) {
      id
      adminGroupName
      scanType
      themeSettings {
        id
        primaryColor
        secondaryColor
        backgroundColor
        logo
        defaultLanguage
        languages
      }
    }
  }
`;

export const getProgressUserHistory = /* GraphQL */ `
  query GetProgressUserHistory($id: ID!) {
    getProgressUserHistory(id: $id) {
      id
      userID
      adminGroupName
      lastVisit
    }
  }
`;
