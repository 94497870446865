/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://b8tsz1qj4b.execute-api.eu-west-2.amazonaws.com/staging",
            "region": "eu-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://nfe7wudqtzei5fwavntdjejl7q.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-4dgabsqxbvae3jzex2zk6swokq",
    "aws_cognito_identity_pool_id": "eu-west-2:131b5714-6e2e-4010-a9ec-ffb8ff9c509a",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_noV6z2uoh",
    "aws_user_pools_web_client_id": "79ade2n8l4e4ucf89r0nm8gkur",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "progressadmind12f437673a643d0b4670e766aacdf1f133410-staging",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
