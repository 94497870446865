/* eslint-disable */
// this is an auto generated file. This will be overwritten
export const createAndSaveBarcode = `
  query CreateAndSaveBarcode($input: String!) {
    createAndSaveBarcode(input: $input)
  }
`;

export const createUser = `
  query CreateUser($input: CreateUserInput!) {
    createUser(input: $input)
  }
`;

export const getUser = `
  query GetUser($input: String!) {
    getUser(input: $input)
  }
`;

export const getTodo = /* GraphQL */ `
  query GetTodo($id: ID!) {
    getTodo(id: $id) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;

export const getProgressPayments = /* GraphQL */ `
  query GetProgressPayments($id: ID!) {
    getProgressPayments(id: $id) {
      id
      userID
      subscriptionType
      startDate
      endDate
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const listProgressPayments = /* GraphQL */ `
  query ListProgressPayments(
    $filter: ModelProgressPaymentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProgressPayments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        subscriptionType
        startDate
        endDate
        isActive
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
