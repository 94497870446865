import { useState, useEffect } from 'react';
import { Auth, Hub } from 'aws-amplify';
import { RootState } from '../store/store';
import { useSelector } from 'react-redux';


interface AuthState {
    isAdmin: boolean | null;
    isAuthenticated: boolean;
    errorMessage: string;
}
function useAuth(): AuthState {
    const [isAdmin, setIsAdmin] = useState<boolean | null>(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const subdomain = useSelector((state: RootState) => state.subdomain.subdomain);


    useEffect(() => {


        const checkAdminGroup = async () => {
            try {

                const currentUser = await Auth.currentAuthenticatedUser();
                const groups = currentUser.signInUserSession.idToken.payload['cognito:groups'];

                if (groups && (groups.includes('admin') && (subdomain && groups.includes(subdomain)))) {
                    setIsAdmin(true);
                    setErrorMessage('');
                    console.log(currentUser);
                } else {
                    setIsAdmin(false);
                    setErrorMessage('Not authorized');
                    Auth.signOut();
                }
            } catch (error) {
                console.error('Error checking admin group:', error);
                setErrorMessage('Error occurred');
            }
        };


        Auth.currentAuthenticatedUser().then(() => {
            checkAdminGroup();
            setIsAuthenticated(true);
        }).catch((err) => {
            console.log(err);
            setIsAuthenticated(false);
        });

        const authListener = (data: { payload: { event: string } }) => {
            switch (data.payload.event) {
                case 'signIn':
                    checkAdminGroup();
                    setIsAuthenticated(true);
                    break;
                case 'signOut':
                    setIsAuthenticated(false);
                    break;
                default:
                    break;
            }
        };

        Hub.listen('auth', authListener);

        return () => {
            Hub.remove('auth', authListener);
        };
    }, [subdomain]);

    return { isAdmin, isAuthenticated, errorMessage };
};

export default useAuth;
