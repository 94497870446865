import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import BarcodeReader from 'react-barcode-reader';
import { API, Auth, Amplify, graphqlOperation } from 'aws-amplify';
import "./SideBar.css";
import { appNavs } from "../../config";
import SideBarNav from "./SidebarNav";
import SidebarNavToggle from "./SidebarNavToggle";
import awsconfig from './../../aws-exports';
import { getProgressPaymentsByUserId } from '../../graphql/custom-queries';
import { createProgressPayments } from './../../graphql/mutations'; // Import the createProgressPayments mutation
import { getUser } from './../../graphql/custom-queries'; // Import the getUser query
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { onCreateProgressUserHistory, onUpdateProgressUserHistory } from '../../graphql/subscriptions';
import { Observable } from 'zen-observable-ts';
import emailIcon from "../../assets/email.svg";
import phoneIcon from '../../assets/phone.svg';
import activeIcon from '../../assets/active.svg';
import soonInactiveIcon from '../../assets/soon.svg';
import inactiveIcon from '../../assets/inactive.svg';
import { Button, SelectField } from '@aws-amplify/ui-react';
import fetchUsers from '../../store/users/fetchUsers';
import { useDispatch } from 'react-redux';
import approve from '../../assets/Approve.mp3';
import decline from '../../assets/Decline.mp3';
import warning from '../../assets/Warning.mp3';


import SubscriptionDialog from '../../pages/dialog/Subscription';





Amplify.configure(awsconfig);

interface Attribute {
  Name: string;
  Value: string;
}


interface User {
  Attributes: Attribute[];
  Username: string;
  Groups: string[];
  Enabled?: boolean;
  EndDate?: any;
  StartDate?: any;
  SubscriptionType?: any;
}

interface SubscriptionResult {
  value: {
    data: {
      onCreateProgressUserHistory?: {
        id: string;
        userID: string;
        adminGroupName: string;
        lastVisited: string;
      };
      onUpdateProgressUserHistory?: {
        id: string;
        userID: string;
        adminGroupName: string;
        lastVisited: string;
      };
    };
  };
}

const Subscriptions = {
  active: {
    color: '#25e572',
    text: 'PREPLATA ISTICE',
    icon: activeIcon,
    button: false,
    sound: approve


  },
  soonInactive: {
    color: '#fe7818',
    text: 'PREPLATA ISTICE',
    icon: soonInactiveIcon,
    button: false,
    sound: warning
  },

  inactive: {
    color: '#e52525',
    text: 'PREPLATA JE ISTEKLA',
    icon: inactiveIcon,
    button: true,
    sound: decline
  }
};





const SideBar = () => {


  const logoUrl = useSelector((state: RootState) => state.logo.url);
  const subdomain = useSelector((state: RootState) => state.subdomain.subdomain);
  const logo = process.env.PUBLIC_URL + logoUrl;


  // const logo = require(logoUrl);
  const [expand, setExpand] = useState(false);
  const [code, setCode] = useState('No result'); // State for barcode
  const [showCodeDialog, setShowCodeDialog] = useState(false); // State to manage the visibility of the barcode dialog
  const [isSubscriptionDialogOpen, setSubscriptionDialogOpen] = useState(false);
  // const [matchedUser, setMatchedUser] = useState<User | null>(null);
  const [foundUser, setfoundUser] = useState<any>(null);
  const { t } = useTranslation();
  const [selectedSubscription, setSelectedSubscription] = useState('30');
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const dispatch = useDispatch();
  const [isScanAllowed, setIsScanAllowed] = useState(true);

  const playSound = (soundFile) => {
    const audio = new Audio(soundFile);
    audio.play();
  };



  const handleScan = async (data) => {
    if (!isScanAllowed) {
      console.log('Scanning not allowed yet. Please wait.');
      return; // Exit the function if a scan is not allowed
    }

    setIsScanAllowed(false); // Disable further scans
    console.log('Scanning...');

    setTimeout(() => {
      setIsScanAllowed(true); // Re-enable scanning after 30 seconds
      console.log('Scan allowed again');
    }, 3000);


    try {
      const response = await API.graphql(graphqlOperation(getUser, { input: data })) as { data: any };
      const userData = response.data.getUser;

      if (userData) {
        const endDate = await fetchPayments(userData.Username);
        const updatedUser = {
          username: userData.Username,
          firstName: userData.Name,
          lastName: userData.Surname,
          email: userData.Email,
          phone: userData.PhoneNumber,
          endDate: endDate[0],
          subscriptionType: endDate[1],
          startDate: endDate[2],
          subscriptionStatus: Subscriptions.active,
        };

        if (updatedUser.endDate) {

          const endDateString = updatedUser.endDate;
          const dateParts = endDateString.split('-');
          const endDate = new Date(parseInt(dateParts[2], 10), parseInt(dateParts[1], 10) - 1, parseInt(dateParts[0], 10));
          const today = new Date();
          const daysUntilExpiration = Math.floor((endDate.getTime() - today.getTime()) / (1000 * 3600 * 24));

          if (daysUntilExpiration <= 0) {
            updatedUser.subscriptionStatus = Subscriptions.inactive;
            playSound(updatedUser.subscriptionStatus.sound);


            setButtonDisabled(true);

          } else if (daysUntilExpiration <= 5) {
            updatedUser.subscriptionStatus = Subscriptions.soonInactive;
            playSound(updatedUser.subscriptionStatus.sound);
            setButtonDisabled(false);
          } else {
            updatedUser.subscriptionStatus = Subscriptions.active;
            playSound(updatedUser.subscriptionStatus.sound);
            setButtonDisabled(false);
          }
          console.log('Subscription inactive');
          console.log(updatedUser.subscriptionStatus.color);
        }

        console.log(updatedUser.username);
        setfoundUser(updatedUser); // Update the state correctly
        setShowCodeDialog(true); // Show the dialog
      } else {
        setCode(`No user found with profile: ${data}`);
        setShowCodeDialog(true); // Show the error in the same dialog for consistency
      }
    } catch (error) {
      console.error('Error fetching user:', error);
      // Handle the error appropriately
      setCode(`Error occurred: ${error.message}`);
      setShowCodeDialog(true);
    }



  };

  async function fetchPayments(userId) {
    const paymentsResult = await API.graphql(graphqlOperation(getProgressPaymentsByUserId, { userID: userId })) as { data: any };;
    console.log(paymentsResult);

    //console.log(paymentsResult.data.progressPaymentsByUserId.items[0].endDate);
    if (paymentsResult.data.progressPaymentsByUserId.items.length) {
      return [paymentsResult.data.progressPaymentsByUserId.items[0].endDate, paymentsResult.data.progressPaymentsByUserId.items[0].subscriptionType, paymentsResult.data.progressPaymentsByUserId.items[0].startDate];
    }

    return 0;
  }
  const handleError = (err) => {
    console.error(err);
  };

  let location = useLocation();

  useEffect(() => {
    const createSub = (API.graphql(
      graphqlOperation(onCreateProgressUserHistory)
    ) as Observable<SubscriptionResult>).subscribe({
      next: async (eventData) => {
        const newUserHistory = eventData.value.data.onCreateProgressUserHistory;
        if (newUserHistory) {
          console.log('New User History:', newUserHistory);
          console.log('New User History:', newUserHistory.userID);
          const userData = await getUserQuery(newUserHistory.userID);
          console.log('New User History:', userData);

          if (userData) {
            const endDate = await fetchPayments(userData.Username);
            const updatedUser = {
              firstName: userData.Name,
              lastName: userData.Surname,
              email: userData.Email,
              endDate: endDate[0],
              subscriptionType: endDate[1],
              startDate: endDate[2],
              subscriptionStatus: Subscriptions.active

            };

            setfoundUser(updatedUser); // Update the state correctly
            setShowCodeDialog(true); // Show the dialog
          }
        }
      }
    });

    const updateSub = (API.graphql(
      graphqlOperation(onUpdateProgressUserHistory)
    ) as Observable<SubscriptionResult>).subscribe({
      next: async (eventData) => {
        const newUserHistory = eventData.value.data.onUpdateProgressUserHistory;
        if (newUserHistory) {
          console.log('New User History:', newUserHistory);
          console.log('New User History:', newUserHistory.userID);
          const userData = await getUserQuery(newUserHistory.userID);
          console.log('New User History:', userData);

          if (userData) {
            const endDate = await fetchPayments(userData.Username);
            const updatedUser = {
              firstName: userData.Name,
              lastName: userData.Surname,
              email: userData.Email,
              endDate: endDate[0],
              subscriptionType: endDate[1],
              startDate: endDate[2],
              subscriptionStatus: Subscriptions.active

            };

            setfoundUser(updatedUser); // Update the state correctly
            setShowCodeDialog(true); // Show the dialog
          }
        }
      }
    });
    fetchUsers(dispatch);
    setExpand(false);


    return () => {
      createSub.unsubscribe();
      updateSub.unsubscribe();
    };

  }, [location]);

  async function getUserQuery(userId) {

    const apiName = 'AdminQueries';
    const path = '/getUser';
    const myInit = {
      queryStringParameters: {
        // Add your parameters here
        username: userId
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      },
    };
    const result = await API.get(apiName, path, myInit);
    console.log(result);
    const user = {
      Username: result.Username,
      Name: result.UserAttributes[5].Value,
      Surname: result.UserAttributes[6].Value,
      Email: result.UserAttributes[7].Value,
    };


    return user;
  }


  function getDate(dateTime) {
    const date = new Date(dateTime);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  async function createNewPayment(userId, subscriptionType) {
    let startDate;
    let endDate;

    //Get last user payments
    const userPayments = await API.graphql(graphqlOperation(getProgressPaymentsByUserId, { userID: userId })) as { data: any };

    if (userPayments.data.progressPaymentsByUserId.items.length > 0) {
      startDate = userPayments.data.progressPaymentsByUserId.items[0].endDate;

      let endDateString = userPayments.data.progressPaymentsByUserId.items[0].endDate;
      let endDateArray = endDateString.split('-');

      endDate = new Date(endDateArray[2], endDateArray[1] - 1, endDateArray[0]);

      if (endDate.getTime() < new Date().getTime()) {
        startDate = getDate(new Date());
        endDate = new Date();
      }
    } else {
      startDate = getDate(new Date());
      endDate = new Date();
    }

    // Determine the number of days to add based on subscriptionType
    let daysToAdd = 0;
    if (subscriptionType === '30') {
      daysToAdd = 30;
    } else if (subscriptionType === '60') {
      daysToAdd = 60;
    } else if (subscriptionType === '90') {
      daysToAdd = 90;
    }
    endDate.setDate(endDate.getDate() + daysToAdd);
    const endDateStr = getDate(endDate);
    const fourMonthsInMilliseconds = 4 * 30 * 24 * 60 * 60 * 1000; // approximately 4 months
    const expireAt = Date.now() + fourMonthsInMilliseconds;

    console.log(expireAt);
    console.log(Date.now());
    console.log('Expire at:', expireAt);

    const newPayment = {
      userID: userId,
      adminGroupName: subdomain,
      subscriptionType,
      startDate,
      endDate: endDateStr,
      isActive: true,
      expireAt,
    };

    console.log(newPayment);
    fetchUsers(dispatch);

    try {
      console.log('Creating new payment...', newPayment);
      const result = await API.graphql(graphqlOperation(createProgressPayments, { input: newPayment }));
      console.log('New payment created:', result);
      setShowCodeDialog(false);
      fetchUsers(dispatch);
    } catch (error) {
      console.error('Error creating new payment:', error);
    }
  }

  const closeSubscriptionDialog = () => {
    setSubscriptionDialogOpen(false);
  };

  const handleSubscription = async (subscriptionType) => {
    if (foundUser) {
      const userId = foundUser.username;
      // console.log(matchedUser);
      console.log(userId);
      await createNewPayment(userId, subscriptionType);
      // Close the dialog after the payment is made
      closeSubscriptionDialog();

    }
  };

  return (
    <>
      <SubscriptionDialog
        isOpen={isSubscriptionDialogOpen}
        handleClose={closeSubscriptionDialog}
        handleSubscription={handleSubscription}
      />
      <BarcodeReader
        onError={handleError}
        onScan={isScanAllowed ? handleScan : null}
      />
      {showCodeDialog && (
        <div className="modal-overlay">
          <div className="codeDialog">
            {foundUser ? (
              <div>
                <div className="user-name">
                  <p>{foundUser.firstName} {foundUser.lastName}</p>
                  <button className="close-button" onClick={() => {
                    setShowCodeDialog(false);
                    setfoundUser(null);
                  }}>
                    <svg width="15" height="15" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_387_104)">
                        <path d="M0.511245 21.2832C0.851517 21.6244 1.29901 21.7947 1.74506 21.7947C2.19136 21.7947 2.63861 21.6244 2.97888 21.2832L11 13.2618L19.0211 21.2832C19.3616 21.6244 19.8089 21.7947 20.2549 21.7947C20.7008 21.7947 21.148 21.6244 21.4888 21.2832C22.1703 20.6017 22.1703 19.4971 21.4888 18.8155L13.4672 10.7944L21.0771 3.1845C21.7588 2.50324 21.7588 1.39813 21.0771 0.716866C20.3958 0.0351262 19.2912 0.0351262 18.6095 0.716866L10.9998 8.32656L3.39031 0.716627C2.70881 0.0348875 1.60394 0.0348875 0.922915 0.716627C0.241176 1.39789 0.241176 2.503 0.922915 3.18426L8.53261 10.7942L0.511722 18.8153C-0.170494 19.4966 -0.170494 20.6012 0.511245 21.2832Z" fill="white" />
                      </g>

                      <defs>
                        <clipPath id="clip0_387_104">
                          <rect width="22" height="22" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                </div>
                <div className="contact-info">
                  <img className="contact-icon" src={emailIcon} alt="email" />
                  <p>{foundUser.email}</p>
                </div>
                <div className="contact-info" style={{ marginTop: '-26px' }}>
                  <img className="contact-icon" src={phoneIcon} alt="phone" />
                  <p >{foundUser.phone}</p>
                </div>
                <hr />
                <p className="subscription-info-1">Preplata: {foundUser.subscriptionType} dana</p>
                <p className="subscription-info-2">Uplaceno: {foundUser.startDate}</p>
                <div className="subscription-section" style={{ backgroundColor: foundUser.subscriptionStatus.color }}>
                  <div className="subscription-details">
                    <div className="subscription-text">
                      <p>{foundUser.subscriptionStatus.text}:</p>
                      <p className="subscription-date">{foundUser.endDate}</p>
                    </div>
                    <div className="subscription-icon">
                      <img src={foundUser.subscriptionStatus.icon} alt="subscription status" />
                    </div>
                  </div>
                </div>
                {buttonDisabled && (
                  <div className="button-container" >
                    <div className="select-container">
                      <SelectField label="Dodaj uplatu" onChange={(e) => setSelectedSubscription(e.target.value)}>
                        <option value='30'>30 {t("days")}</option>
                        <option value='60'>60 {t("days")}</option>
                        <option value='90'>90 {t("days")}</option>
                      </SelectField>
                    </div>
                    <div className="button-right">
                      <Button onClick={() => handleSubscription(selectedSubscription)}>Potvrdi</Button>
                    </div>
                  </div>
                )}

              </div>
            ) : (
              <p>{code}</p>
            )}
          </div>
        </div>
      )}
      <div className="btn-sidebar-nav">
        <SidebarNavToggle expand={expand} onChange={() => setExpand(!expand)} />
      </div>
      <div className={"sidebar " + (expand ? "visible" : "")}>
        <div className="sidebar-logo">
          <img src={logo} alt="logo" />
        </div>
        <SideBarNav navs={appNavs} />
      </div>
    </>
  );
};

export default SideBar;

