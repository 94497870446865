import React, { useEffect } from 'react';
import { Authenticator, ThemeProvider } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Amplify, Hub } from 'aws-amplify';
import awsconfig from './../../aws-exports';
import { useNavigate } from 'react-router-dom';
import './Login.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import useTheme from '../../theme';

Amplify.configure(awsconfig);

export default function Login() {
  const navigate = useNavigate();
  const logoUrl = useSelector((state: RootState) => state.logo.url);
  const logo = process.env.PUBLIC_URL + logoUrl;
  const theme = useTheme();
  const selectedLanguage = useSelector((state: RootState) => state.language.selectedLanguage);

  useEffect(() => {
    const listener = (data) => {
      switch (data.payload.event) {
        case 'signIn':
          navigate('/');
          break;
        default:
          break;
      }
    };

    Hub.listen('auth', listener);

    return () => {
      Hub.remove('auth', listener);
    };
  }, [navigate]);

  return (
    <ThemeProvider theme={theme}>
      <div className="login">
        <div className="login-logo">
          <img src={logo} alt="logo" />
        </div>
        {/* The key prop is set to selectedLanguage, so the Authenticator will remount on language change */}
        <Authenticator key={selectedLanguage} hideSignUp />
      </div>
    </ThemeProvider>
  );
}
