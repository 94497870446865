import React from "react";
import { Icon } from "@aws-amplify/ui-react";

import {
  MdDashboard,
  MdPeople,
  MdLogout,
  MdSettings,
} from "react-icons/md";

export const baseConfig = {
  projectLink: "/", // GitHub link in the navbar
  docsRepositoryBase: "", // base URL for the docs repository
  titleSuffix: "",
  search: true,
  header: true,
  headerText: "Logo",
  footer: true,
  footerText: (
    <>
    </>
  ),

  logo: (
    <>
      <img
        alt="logo"
        width="130"
        height="52"
      />
    </>
  ),
};

/// Navigation sidebar
export const appNavs = [
  {
    eventKey: "dashboard",
    icon: <Icon as={MdDashboard} />,
    title: "Dashboard",
    to: "/",
  },

  {
    eventKey: "tables",
    icon: <Icon as={MdPeople} />,
    title: "Korisnici",
    to: "/tables",
  },
  // {
  //   eventKey: "theme",
  //   icon: <Icon as={MdSettings} />,
  //   title: "Brend podešavanja",
  //   to: "/theme",
  // },
  // {
  //   eventKey: "scan",
  //   icon: <Icon as={MdSettings} />,
  //   title: "Brend podešavanja",
  //   to: "/scanner-settings",
  // },
  // {
  //   eventKey: "profile",
  //   icon: <Icon as={MdAccountBox} />,
  //   title: "Profil",
  //   to: "/profile",
  // },
  {
    eventKey: "logout",
    icon: <Icon as={MdLogout} />,
    title: "Izloguj se",
  },
];
