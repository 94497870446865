import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ThemeColors {
    backgroundColor: string;
    primaryButton: string;
    primaryButtonHover: string;
    secondaryButton: string;
    secondaryButtonHover: string;
    primaryFont: string;
    secondaryFont: string;
    sidebarBackgroundColor?: string;
}

interface ThemeState {
    colors: ThemeColors;
}

const initialState: ThemeState = {
    colors: {
        backgroundColor: "#232323",
        primaryButton: "#7C66EB",
        primaryButtonHover: "#573CDF",
        secondaryButton: "#DCC3A9",
        secondaryButtonHover: "#FFFFFF",
        primaryFont: "#0d1a26",
        secondaryFont: "#DCC3A9",
        sidebarBackgroundColor: "#121212",
    },
};

export const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        setBackgrounColor: (state, action: PayloadAction<string>) => {
            state.colors.backgroundColor = action.payload;
        },
        setPrimaryButtonColor: (state, action: PayloadAction<string>) => {
            state.colors.primaryButton = action.payload;
        },
        setPrimaryButtonHoverColor: (state, action: PayloadAction<string>) => {
            state.colors.primaryButtonHover = action.payload;
        },
        setPrimaryFontColor: (state, action: PayloadAction<string>) => {
            state.colors.primaryFont = action.payload;
        },
        setSecondaryFontColor: (state, action: PayloadAction<string>) => {
            state.colors.secondaryFont = action.payload;
        },
        setSidebarBackgroundColor: (state, action: PayloadAction<string>) => {
            state.colors.sidebarBackgroundColor = action.payload;
        }

    },
});

export const {
    setBackgrounColor,
    setPrimaryButtonColor,
    setPrimaryButtonHoverColor,
    setPrimaryFontColor,
    setSidebarBackgroundColor,
    setSecondaryFontColor,

} = themeSlice.actions;

export default themeSlice.reducer;
