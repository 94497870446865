
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SubdomainState {
    subdomain: string;
}

const initialState: SubdomainState = {
    subdomain: 'progressbjj',

};

const subdomainSlice = createSlice({
    name: 'Domain',
    initialState,
    reducers: {
        setSubdomain: (state, action: PayloadAction<string>) => {
            state.subdomain = action.payload;
        },
    },
});


export const { setSubdomain } = subdomainSlice.actions;

export default subdomainSlice.reducer;
