import React, { useState, useRef } from 'react';
import { RadioGroupField, Radio, Button } from '@aws-amplify/ui-react';
import QRCode from 'qrcode.react';
import { updateAppSettings } from '../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';

const Scanner = () => {
    const [scannerType, setScannerType] = useState('qr');
    const [qrValue, setQrValue] = useState('client1'); // QR code value
    const qrRef = useRef<HTMLDivElement>(null);

    const updateScanType = async () => {
        try {
            const input = {
                id: qrValue,
                scanType: scannerType.toUpperCase()
            };
            const response = await API.graphql(graphqlOperation(updateAppSettings, { input }));
            console.log('Scan type updated:', response);
        } catch (error) {
            console.error('Error updating scan type:', error);
        }
    };

    const downloadQRCode = () => {
        if (qrRef.current) {
            const canvas = qrRef.current.querySelector('canvas');
            if (canvas) {
                const image = canvas.toDataURL('image/png');
                const link = document.createElement('a');
                link.href = image;
                link.download = 'qr-code.png';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    };

    return (
        <div>
            <h2>Scanner Settings</h2>
            <RadioGroupField
                label="Set Scanner Option"
                name="scannerType"
                value={scannerType}
                onChange={(event) => setScannerType(event.target.value)}
            >
                <Radio value="barcode">Barcode Scanner</Radio>
                <Radio value="qr">QR Scanner</Radio>
                <Button onClick={updateScanType}>Update Scan Type</Button>
            </RadioGroupField>

            {scannerType === 'qr' && (
                <div ref={qrRef} className="qr-container">
                    <h2>QR Code for your app </h2>
                    <QRCode value={qrValue} size={356} includeMargin={true} />
                    <br />
                    <Button onClick={downloadQRCode}>Download QR Code</Button>
                </div>

            )}
        </div>
    );
};

export default Scanner;
