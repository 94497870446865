import React from 'react';
import { useTranslation } from 'react-i18next';
import './ToastNotifications.css';

export default function ToastNotifications(props) {
    const { className } = props;
    const { t } = useTranslation();

    return(
        <div className={`toast-notifications ${className}`}>          
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="12" fill="#25E572"/>
                <path d="M9.0199 18C9.00614 18 8.99238 18 8.97987 17.9987C8.59333 17.9849 8.23681 17.7823 8.02916 17.4537C6.72693 15.3996 4.44771 13.4865 4.4252 13.4676C3.92607 13.051 3.85602 12.3058 4.27008 11.8024C4.68414 11.3002 5.4247 11.2297 5.92382 11.645C6.00889 11.7155 7.66013 13.0988 9.0812 14.8861C10.521 13.2486 13.5158 10.2114 18.1893 7.18813C18.7347 6.83445 19.4628 6.9943 19.813 7.54307C20.1645 8.09185 20.0057 8.82438 19.4603 9.17681C13.118 13.2826 9.99564 17.4777 9.96561 17.5192C9.74295 17.8213 9.39143 18 9.0199 18Z" fill="white"/>
            </svg>         
            <p><span>{props.name}</span> {t("added_successfully")}!</p>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="10.0312" y="-0.00012207" width="1.82452" height="14.1822" rx="0.912261" transform="rotate(45 10.0312 -0.00012207)" fill="white"/>
                <rect x="11.125" y="9.85236" width="1.82452" height="13.8664" rx="0.912261" transform="rotate(135 11.125 9.85236)" fill="white"/>
            </svg>
        </div>
    );
}