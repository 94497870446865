// store.ts
import { configureStore } from '@reduxjs/toolkit';
import languageReducer from './language/languageSlice';
import usersReducer from './users/usersSlice';
import themeReducer from './theme/themeSlice';
import logoSlice from './theme/logoSlice';
import subdomainSlice from './subdomain/subdomainSlice';

export const store = configureStore({
    reducer: {
        language: languageReducer,
        users: usersReducer,
        theme: themeReducer,
        logo: logoSlice,
        subdomain: subdomainSlice,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
