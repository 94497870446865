import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          number_of_users: "Number of users",
          active_users: "Active users",
          inactive_users: "Inactive users",
          payments_statistics: "Payments statistics",
          new_users: "New users",
          users: "Users",
          name_and_surname: "Name and surname",
          email: "Email",
          phone_number: "Phone number",
          active_subscription_until: "Active subscription until",
          action: "Action",
          add_user: "Add user",
          payment: "Payment",
          name: "Name",
          surname: "Surname",
          add: "Add",
          added_successfully: "added successfully",
          choose_the_payment_type: "Choose the payment type",
          user_has_paid: "User has paid",
          days: "days",
          confirm: "Confirm",
          notification: "Notification",
          are_you_sure_you_want_to_log_out: "Are you sure you want to log out?",
          log_out: "Log out",
          close: "Close",
          pay: "Pay",
          dashboard_menu: "Dashboard",
          theme_menu: "Theme settings",
          tables_menu: "Users",
          scan_menu: "Scanner settings",
          logout_menu: "Log out",
          number_of_payments: "Number of payments",
          logo: "Logo",
          primary_color: "Primary color",
          secondary_color: "Secondary color",
          primary_button: "Primary button",
          languages: "Languages",
          edit: "Edit",
          setting: "Setting",
          value: "Value",
          select_language: "Select language",
          language_en: "English",
          language_es: "Spanish",
          language_fr: "French",
          language_sr: "Serbian",
          language_ru: "Russian",
          primary_font_color: "Primary font color",
          background_color: "Background color",
          secondary_font_color: "Secondary font color",
          primary_button_color: "Primary button color",
          secondary_button_color: "Secondary button color",
          primary_button_hover_color: "Primary button hover color",
          secondary_button_hover_color: "Secondary button hover color",
          login_background_color: "Login background color",
          table_border_color: "Table border color",
          table_font_color: "Table font color",
          sidebar_background_color: "Sidebar background color",
          save: "Save",
          upload_logo: "Upload logo",
          default_language: "Default language",

        }
      },
      es: { // Spanish translations
        translation: {
          number_of_users: "Número de usuarios",
          active_users: "Usuarios activos",
          inactive_users: "Usuarios inactivos",
          payments_statistics: "Estadísticas de pagos",
          new_users: "Usuarios nuevos",
          users: "Usuarios",
          name_and_surname: "Nombre y apellido",
          email: "Correo electrónico",
          phone_number: "Número de teléfono",
          active_subscription_until: "Suscripción activa hasta",
          action: "Acción",
          add_user: "Añadir usuario",
          payment: "Pago",
          name: "Nombre",
          surname: "Apellido",
          add: "Añadir",
          added_successfully: "añadido exitosamente",
          choose_the_payment_type: "Elegir tipo de pago",
          user_has_paid: "El usuario ha pagado",
          days: "días",
          confirm: "Confirmar",
          notification: "Notificación",
          are_you_sure_you_want_to_log_out: "¿Estás seguro de que quieres cerrar sesión?",
          log_out: "Cerrar sesión",
          close: "Cerrar",
          pay: "Pagar",
          dashboard_menu: "Tablero",
          theme_menu: "Configuración de tema",
          tables_menu: "Usuarios",
          scan_menu: "Configuración de scanner",
          logout_menu: "Cerrar sesión",
          number_of_payments: "Número de pagos",
          logo: "Logo",
          primary_color: "Color primario",
          secondary_color: "Color secundario",
          primary_button: "Botón primario",
          languages: "Idiomas",
          edit: "Editar",
          setting: "Configuración",
          value: "Valor",
          select_language: "Seleccionar idioma",
          language_en: "Inglés",
          language_es: "Español",
          language_fr: "Francés",
          language_sr: "Serbio",
          language_ru: "Ruso",
          primary_font_color: "Color primario de fuente",
          background_color: "Color de fondo",
          secondary_font_color: "Color secundario de fuente",
          primary_button_color: "Color del botón primario",
          secondary_button_color: "Color del botón secundario",
          primary_button_hover_color: "Color de botón primario al pasar el ratón",
          secondary_button_hover_color: "Color de botón secundario al pasar el ratón",
          login_background_color: "Color de fondo de inicio de sesión",
          table_border_color: "Color del borde de la tabla",
          table_font_color: "Color de fuente de la tabla",
          sidebar_background_color: "Color de fondo de la barra lateral",
          save: "Guardar",
          upload_logo: "Subir logo",
          default_language: "Idioma predeterminado",
        }
      },
      fr: { // French translations
        translation: {
          number_of_users: "Nombre d'utilisateurs",
          active_users: "Utilisateurs actifs",
          inactive_users: "Utilisateurs inactifs",
          payments_statistics: "Statistiques de paiements",
          new_users: "Nouveaux utilisateurs",
          users: "Utilisateurs",
          name_and_surname: "Nom et prénom",
          email: "E-mail",
          phone_number: "Numéro de téléphone",
          active_subscription_until: "Abonnement actif jusqu'à",
          action: "Action",
          add_user: "Ajouter un utilisateur",
          payment: "Paiement",
          name: "Prénom",
          surname: "Nom",
          add: "Ajouter",
          added_successfully: "ajouté avec succès",
          choose_the_payment_type: "Choisir le type de paiement",
          user_has_paid: "L'utilisateur a payé",
          days: "jours",
          confirm: "Confirmer",
          notification: "Notification",
          are_you_sure_you_want_to_log_out: "Êtes-vous sûr de vouloir vous déconnecter?",
          log_out: "Se déconnecter",
          close: "Fermer",
          pay: "Payer",
          dashboard_menu: "Tableau de bord",
          theme_menu: "Paramètres du thème",
          tables_menu: "Utilisateurs",
          logout_menu: "Se déconnecter",
          number_of_payments: "Nombre de paiements",
          logo: "Logo",
          primary_color: "Couleur principale",
          secondary_color: "Couleur secondaire",
          primary_button: "Bouton principal",
          languages: "Langues",
          edit: "Éditer",
          setting: "Réglage",
          value: "Valeur",
          select_language: "Sélectionner la langue",
          language_en: "Anglais",
          language_es: "Espagnol",
          language_fr: "Français",
          language_sr: "Serbe",
          language_ru: "Russe",
          primary_font_color: "Couleur principale de la police",
          background_color: "Couleur d'arrière-plan",
          secondary_font_color: "Couleur secondaire de la police",
          primary_button_color: "Couleur du bouton principal",
          secondary_button_color: "Couleur du bouton secondaire",
          primary_button_hover_color: "Couleur du bouton principal au survol",
          secondary_button_hover_color: "Couleur du bouton secondaire au survol",
          login_background_color: "Couleur de fond de la connexion",
          table_border_color: "Couleur de la bordure du tableau",
          table_font_color: "Couleur de la police du tableau",
          sidebar_background_color: "Couleur de fond de la barre latérale",
          save: "Sauvegarder",
          upload_logo: "Télécharger le logo",
          default_language: "Langue par défaut",
        }
      },

      sr: {
        translation: {
          number_of_users: "Broj korisnika",
          active_users: "Aktivni korisnici",
          inactive_users: "Neaktivni korisnici",
          payments_statistics: "Statistika uplata",
          new_users: "Novi korisnici",
          users: "Korisnici",
          name_and_surname: "Ime i prezime",
          email: "Email",
          phone_number: "Broj telefona",
          active_subscription_until: "Aktivna pretplata until",
          action: "Akcija",
          add_user: "Dodaj korisnika",
          payment: "Uplata",
          name: "Ime",
          surname: "Prezime",
          add: "Dodaj",
          added_successfully: "uspesno dodat",
          choose_the_payment_type: "Izaberite tip uplate",
          user_has_paid: "Korisnik je uplatio",
          days: "dana",
          confirm: "Potvrdi",
          notification: "Obavestenje",
          are_you_sure_you_want_to_log_out: "Da li ste sigurni da zelite da se izlogujete?",
          log_out: "Izloguj se",
          close: "Zatvori",
          pay: "Uplati",
          dashboard_menu: "Dashboard",
          theme_menu: "Podešavanja teme",
          tables_menu: "Korisnici",
          scan_menu: "Scanner podešavanja",
          logout_menu: "Izloguj se",
          number_of_payments: "Broj uplata",
          logo: "Logo",
          primary_color: "Primarna boja",
          secondary_color: "Sekundarna boja",
          primary_button: "Primarno dugme",
          languages: "Jezici",
          edit: "Izmeni",
          setting: "Podešavanje",
          value: "Vrednost",
          background_color: "Boja pozadine",
          primary_font_color: "Primarna boja fonta",
          select_language: "Izaberite jezik",
          language_en: "Engleski",
          language_es: "Španski",
          language_fr: "Francuski",
          language_sr: "Srpski",
          language_ru: "Ruski",
          secondary_font_color: "Sekundarna boja fonta",
          primary_button_color: "Primarna boja dugmeta",
          secondary_button_color: "Sekundarna boja dugmeta",
          primary_button_hover_color: "Primarna boja dugmeta hover",
          secondary_button_hover_color: "Sekundarna boja dugmeta hover",
          login_background_color: "Boja pozadine pri logovanju",
          table_border_color: "Boja ivice tabele",
          table_font_color: "Boja fonta tabele",
          sidebar_background_color: "Boja pozadine sidebar-a",
          save: "Sačuvaj",
          upload_logo: "Dodaj logo",
          default_language: "Podrazumevani jezik",

        }
      },
      "ru": {
        "translation": {
          number_of_users: "Количество пользователей",
          active_users: "Активные пользователи",
          inactive_users: "Неактивные пользователи",
          payments_statistics: "Статистика платежей",
          new_users: "Новые пользователи",
          users: "Пользователи",
          name_and_surname: "Имя и фамилия",
          email: "Электронная почта",
          phone_number: "Номер телефона",
          active_subscription_until: "Активная подписка до",
          action: "Действие",
          add_user: "Добавить пользователя",
          payment: "Платеж",
          name: "Имя",
          surname: "Фамилия",
          add: "Добавить",
          added_successfully: "успешно добавлено",
          choose_the_payment_type: "Выберите тип платежа",
          user_has_paid: "Пользователь оплатил",
          days: "дней",
          confirm: "Подтвердить",
          notification: "Уведомление",
          are_you_sure_you_want_to_log_out: "Вы уверены, что хотите выйти?",
          log_out: "Выйти",
          close: "Закрыть",
          pay: "Оплатить",
          dashboard_menu: "Панель управления",
          theme_menu: "Настройки темы",
          tables_menu: "Пользователи",
          logout_menu: "Выйти",
          number_of_payments: "Количество платежей",
          logo: "Логотип",
          primary_color: "Основной цвет",
          secondary_color: "Вторичный цвет",
          primary_button: "Основная кнопка",
          languages: "Языки",
          edit: "Редактировать",
          setting: "Настройка",
          value: "Значение",
          background_color: "Цвет фона",
          primary_font_color: "Основной цвет шрифта",
          select_language: "Выберите язык",
          language_en: "Английский",
          language_es: "Испанский",
          language_fr: "Французский",
          language_sr: "Сербский",
          language_ru: "Русский",
          secondary_font_color: "Вторичный цвет шрифта",
          primary_button_color: "Цвет основной кнопки",
          secondary_button_color: "Цвет вторичной кнопки",
          primary_button_hover_color: "Цвет основной кнопки при наведении",
          secondary_button_hover_color: "Цвет вторичной кнопки при наведении",
          login_background_color: "Цвет фона при входе",
          table_border_color: "Цвет границы таблицы",
          table_font_color: "Цвет шрифта таблицы",
          sidebar_background_color: "Цвет фона боковой панели",
          save: "Сохранить",
          upload_logo: "Загрузить логотип",
          default_language: "Язык по умолчанию",
        }
      }
    }
  });

export default i18n;