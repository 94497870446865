import { setUsers, setUsersCount, setActiveUsers, setInactiveUsers, setNewUsersByMonth } from './usersSlice';
import { getProgressPaymentsByUserId, listNonAdminUsers } from '../../graphql/custom-queries';
import { API, graphqlOperation } from 'aws-amplify';

// Add users to state 
async function fetchUsers(dispatch: any) {
    try {
      const result: any = await API.graphql({
        query: listNonAdminUsers,
        variables: {}  // Since listNonAdminUsers doesn't require any variables, this is an empty object.
      });

      let resultUsers = result.data.listNonAdminUsers;
      let allUsersCount = 0;
      let activeUsersCount = 0;
      let inactiveUsersCount = 0;

      let newUserCountsByMonth = {
        Jan: 0, Feb: 0, Mar: 0, Apr: 0, May: 0, Jun: 0,
        Jul: 0, Aug: 0, Sep: 0, Oct: 0, Nov: 0, Dec: 0
      };

      for(const user of resultUsers){
        allUsersCount++;

        const createdAt = new Date(user.CreatedAt);
        const month = createdAt.toLocaleString('default', { month: 'short' });
        newUserCountsByMonth[month] = newUserCountsByMonth[month] + 1;

        const userId = user.Username;
        const paymentsResult = await API.graphql(graphqlOperation(getProgressPaymentsByUserId, { userID: userId })) as { data: any };

        // If user has endDate, set it to the user
        if (paymentsResult.data.progressPaymentsByUserId.items.length > 0 && paymentsResult.data.progressPaymentsByUserId.items[0].endDate) {
          const endDate = paymentsResult.data.progressPaymentsByUserId.items[0].endDate;
          
          resultUsers = resultUsers.map(u => u.Username === user.Username ? { ...u, EndDate: endDate } : u);

          const dateParts = endDate.split('-');
          const endDate2 = new Date(parseInt(dateParts[2], 10), parseInt(dateParts[1], 10) - 1, parseInt(dateParts[0], 10));
          
          const currentDate = new Date();
          const hasPassed = endDate2 && endDate2.getTime() < currentDate.getTime();

          if(hasPassed){
            inactiveUsersCount++;
          }else{
            activeUsersCount++;
          }
        }else{
          inactiveUsersCount++;
        }
      }

      dispatch(setUsers(resultUsers));
      dispatch(setUsersCount(allUsersCount));
      dispatch(setActiveUsers(activeUsersCount));
      dispatch(setInactiveUsers(inactiveUsersCount));
      dispatch(setNewUsersByMonth(newUserCountsByMonth));
    }
    catch (error) {
      console.error('Error fetching users', error);
    }
  }

  export default fetchUsers;