import { I18n } from 'aws-amplify';


I18n.putVocabularies({
    'en': {
        // English translations
        'Sign in': 'Sign in',
        'Signing in': 'Signing in',
        'Forgot your password?': 'Forgot your password?',
        'Back to Sign In': 'Back to Sign In',
        'Send code': 'Send code',
        'Send Code': 'Send Code',
        'Enter your Email': 'Enter your Email',
        'Enter your Password': 'Enter your Password',
        'Reset Password': 'Reset Password',
        'Enter your email': 'Enter your email',
        'Email': 'Email',
        'Password': 'Password',
    },
    'sr': {
        // Serbian translations
        'Sign in': 'Prijavi se',
        'Signing in': 'Prijavljivanje',
        'Forgot your password?': 'Zaboravljena lozinka?',
        'Back to Sign In': 'Nazad na prijavu',
        'Send code': 'Posalji kod',
        'Send Code': 'Posalji kod',
        'Enter your Email': 'Unesite email',
        'Enter your Password': 'Unesite lozinku',
        'Reset Password': 'Resetuj lozinku',
        'Enter your email': 'Unesite email',
        'Email': 'Email',
        'Password': 'Lozinka',
    },
    'es': {
        //Spanish translations
        'Sign in': 'Iniciar sesión',
        'Signing in': 'Iniciando sesión',
        'Forgot your password?': '¿Olvidó su contraseña?',
        'Back to Sign In': 'Volver a iniciar sesión',
        'Send code': 'Enviar código',
        'Send Code': 'Enviar código',
        'Enter your Email': 'Ingrese su email',
        'Enter your Password': 'Ingrese su contraseña',
        'Reset Password': 'Restablecer contraseña',
        'Enter your email': 'Ingrese su email',
        'Email': 'Email',
        'Password': 'Contraseña',
    },
    'fr': {
        // French translations
        'Sign in': 'Se connecter',
        'Signing in': 'Connexion en cours',
        'Forgot your password?': 'Mot de passe oublié?',
        'Back to Sign In': 'Retour à la connexion',
        'Send code': 'Envoyer le code',
        'Send Code': 'Envoyer le code',
        'Enter your Email': 'Entrez votre email',
        'Enter your Password': 'Entrez votre mot de passe',
        'Reset Password': 'Réinitialiser le mot de passe',
        'Enter your email': 'Entrez votre email',
        'Email': 'Email',
        'Password': 'Mot de passe',
    },
    'ru': {
        // Russian translations
        'Sign in': 'Войти',
        'Signing in': 'Вход',
        'Forgot your password?': 'Забыли пароль?',
        'Back to Sign In': 'Назад к входу',
        'Send code': 'Отправить код',
        'Send Code': 'Отправить код',
        'Enter your Email': 'Введите ваш мейл',
        'Enter your Password': 'Введите ваш пароль',
        'Reset Password': 'Сбросить пароль',
        'Enter your email': 'Введите ваш мейл',
        'Email': 'Мейл',
        'Password': 'Пароль',
    },

});
