import { createSlice } from '@reduxjs/toolkit';

interface User {
    Username: string;
    Name: string;
    Surname: string;
    Email: string;
    PhoneNumber: string;
    EndDate: string;
    CreatedAt: string;
}

interface usersState {
    users: User[];
    users_count: number;
    active_user: number;
    inactive_user: number;
    new_users_by_month: {}
}

const initialState: usersState = {
    users: [],
    users_count: 0,
    active_user: 0,
    inactive_user: 0,
    new_users_by_month: {
        Jan: 0, Feb: 0, Mar: 0, Apr: 0, May: 0, Jun: 0,
        Jul: 0, Aug: 0, Sep: 0, Oct: 0, Nov: 0, Dec: 0
    }
};

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setUsers: (state, action) => {
            state.users = action.payload;
        },
        setUsersCount: (state, action) => {
            state.users_count = action.payload;
        },
        setActiveUsers: (state, action) => {
            state.active_user = action.payload;
        },
        setInactiveUsers: (state, action) => {
            state.inactive_user = action.payload;
        },
        setNewUsersByMonth: (state, action) => {
            state.new_users_by_month = action.payload;
        }
    },
});

export const { setUsers, setUsersCount, setActiveUsers, setInactiveUsers, setNewUsersByMonth } = usersSlice.actions;
export default usersSlice.reducer;