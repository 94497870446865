
import React from 'react';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import useAuth from './hooks/UseAuth';
import useTheme from './theme';
import LanguageSelector from './components/Common/LanguageSelector';
import Layout from './components/Layout';
import Dashboard from './pages/dashboard';
import Theme from './pages/theme/Theme';
import Scanner from './pages/scanner/Scanner';
import Forms from './pages/forms';
import EditForm from './pages/forms/EditForm';
import Tables from './pages/tables';
import UsersTable from './pages/tables/UsersTablePage';
import Profile from './pages/profile';
import Login from './pages/login';
import PrivateRoute from './components/PrivateRoute';
import NoMatch from './pages/noMatch/NoMatch';
import { Routes, Route } from 'react-router-dom';
import '@aws-amplify/ui-react/styles.css';
import './App.css';
import { ThemeProvider } from '@aws-amplify/ui-react';
import { setSubdomain } from './store/subdomain/subdomainSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './store/store';
import CustomErrorPage from './pages/error/ErrorPage';

Amplify.configure(awsconfig);

function App() {
  const theme = useTheme();
  const { isAdmin, isAuthenticated } = useAuth();
  const dispatch = useDispatch();
  const subdomain = useSelector((state: RootState) => state.subdomain.subdomain);
  const subdomainUrl = window.location.href;
  console.log('subdomain', subdomain);
  const subdomainFromUrl = getSubdomain(subdomainUrl);

  // dispatch(setSubdomain(subdomainFromUrl));

  dispatch(setSubdomain('progressbjj'));


  function getSubdomain(url) {
    console.log('url', url);
    const hostname = new URL(url).hostname;
    const parts = hostname.split('.');

    if (parts.length > 2) {
      return parts[0];
    }

    return '';
  }


  return (
    <ThemeProvider theme={theme}>
      <LanguageSelector />
      {isAuthenticated ? (
        <Routes>
          <Route path="/" element={<PrivateRoute isAdmin={isAdmin} />}>
            <Route element={<Layout />}>
              <Route path="*" element={<CustomErrorPage />} />
              <Route index element={<Dashboard />} />
              <Route path="theme" element={<Theme />} />
              <Route path='scanner-settings' element={<Scanner />} />
              <Route path="forms" element={<Forms />} />
              <Route path="edit-form" element={<EditForm />} />
              <Route path="tables" element={<Tables />} />
              <Route path="users-table" element={<UsersTable />} />
              <Route path="profile" element={<Profile />} />
            </Route>
          </Route>
          <Route path="*" element={<NoMatch />} />
        </Routes>
      ) : (
        <Login />
      )}
    </ThemeProvider>
  );
};

export default App;
