import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

interface PrivateRouteProps {
    isAdmin: boolean | null;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ isAdmin }) => {
    return isAdmin ? <Outlet /> : <Navigate to="/" replace />;
};

export default PrivateRoute;
