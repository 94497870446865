import React, { useState } from 'react';
import {
    Button,
    Flex,
    TextField,
    SelectField
} from "@aws-amplify/ui-react";
import './Dialog.css';
import { Amplify, Auth } from 'aws-amplify';
import awsconfig from '../../aws-exports';
import { createAndSaveBarcode, createUser } from '../../graphql/custom-queries';
import { API, graphqlOperation } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import ToastNotifications from './../../components/ToastNotifications/ToastNotifications';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import { createProgressPayments } from './../../graphql/mutations'; // Import the createProgressPayments mutation
import { getProgressPaymentsByUserId } from '../../graphql/custom-queries';
import fetchUsers from '../../store/users/fetchUsers';
import { useDispatch } from 'react-redux';



const add_user_icon = require('./../../assets/add_user_icon.png');

Amplify.configure(awsconfig);

const FormDialog = ({ isOpen, handleClose }) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [buttonClicked, setButtonClicked] = useState(false);
    const [name, setName] = useState('');
    const [toastClass, setToastClass] = useState('show');
    const { t } = useTranslation();
    const [firstNameError, setFirstNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState('');
    const subdomain = useSelector((state: RootState) => state.subdomain.subdomain);
    const [selectedSubscription, setSelectedSubscription] = useState('30');
    const dispatch = useDispatch();
    const [touched, setTouched] = useState({
        firstName: false,
        lastName: false,
        email: false,
        phoneNumber: false,
    });



    const generateProfileName = (lastName) => {
        const randomNumberLength = 10 - lastName.length;
        const randomNumber = Math.floor(Math.random() * Math.pow(10, randomNumberLength));
        const profileName = lastName + randomNumber;
        return profileName;
    };
    const validateInput = () => {
        let isValid = true;

        // Check if firstName is filled
        if (firstName === '') {
            setFirstNameError('Name is required');
            isValid = false;
        } else {
            setFirstNameError('');
        }

        // Check if lastName is filled
        if (lastName === '') {
            setLastNameError('Surname is required');
            isValid = false;
        } else {
            setLastNameError('');
        }

        // Check if email is filled
        if (email === '') {
            setEmailError('Email is required');
            isValid = false;
        } else {
            setEmailError('');
        }

        // Validate phone number format
        const phoneRegex = /^\+\d{1,3}\d{3,15}$/;

        // Validate phone number format
        if (!phoneRegex.test(phoneNumber)) {
            setPhoneNumberError('Invalid phone number.');
            isValid = false;
        } else {
            setPhoneNumberError('');
        }

        return isValid;
    };
    const handleInputChange = (e, field) => {
        const value = e.target.value;
        switch (field) {
            case 'firstName':
                setFirstName(value);
                break;
            case 'lastName':
                setLastName(value);
                break;
            case 'email':
                setEmail(value);
                if (!/\S+@\S+\.\S+/.test(value)) { // Basic email regex
                    setEmailError('Invalid email');
                } else {
                    setEmailError('');
                }
                break;
            case 'phoneNumber':
                setPhoneNumber(value);
                const phoneRegex = /^\+?[0-9]{10,15}$/; // Adjusted phone number regex
                if (!phoneRegex.test(value)) {
                    setPhoneNumberError('Invalid phone number');
                } else {
                    setPhoneNumberError('');
                }
                break;
            default:
                break;
        }
        // Mark field as touched
        setTouched({ ...touched, [field]: true });
    };

    function getDate(dateTime) {
        const date = new Date(dateTime);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    }

    async function createNewPayment(userId, subscriptionType) {
        let startDate;
        let endDate;

        //Get last user payments
        const userPayments = await API.graphql(graphqlOperation(getProgressPaymentsByUserId, { userID: userId })) as { data: any };

        if (userPayments.data.progressPaymentsByUserId.items.length > 0) {
            startDate = userPayments.data.progressPaymentsByUserId.items[0].endDate;

            let endDateString = userPayments.data.progressPaymentsByUserId.items[0].endDate;
            let endDateArray = endDateString.split('-');

            endDate = new Date(endDateArray[2], endDateArray[1] - 1, endDateArray[0]);

            if (endDate.getTime() < new Date().getTime()) {
                startDate = getDate(new Date());
                endDate = new Date();
            }
        } else {
            startDate = getDate(new Date());
            endDate = new Date();
        }

        // Determine the number of days to add based on subscriptionType
        let daysToAdd = 0;
        if (subscriptionType === '30') {
            daysToAdd = 30;
        } else if (subscriptionType === '60') {
            daysToAdd = 60;
        } else if (subscriptionType === '90') {
            daysToAdd = 90;
        }
        endDate.setDate(endDate.getDate() + daysToAdd);
        const endDateStr = getDate(endDate);
        const fourMonthsInMilliseconds = 4 * 30 * 24 * 60 * 60 * 1000; // approximately 4 months
        const expireAt = Date.now() + fourMonthsInMilliseconds;

        console.log(expireAt);
        console.log(Date.now());
        console.log('Expire at:', expireAt);

        const newPayment = {
            userID: userId,
            adminGroupName: subdomain,
            subscriptionType,
            startDate,
            endDate: endDateStr,
            isActive: true,
            expireAt,
        };

        console.log(newPayment);
        fetchUsers(dispatch);

        try {
            console.log('Creating new payment...', newPayment);
            const result = await API.graphql(graphqlOperation(createProgressPayments, { input: newPayment }));
            console.log('New payment created:', result);
            //   setShowCodeDialog(false);
            //   fetchUsers();
        } catch (error) {
            console.error('Error creating new payment:', error);
        }
    }




    const handleSignUp = async () => {
        if (!validateInput()) {
            return;
        }
        setButtonClicked(true);
        let profileName = generateProfileName(lastName);
        try {
            const userDetails = {
                email: email,
                firstName: firstName,
                lastName: lastName,
                name: profileName,
                phoneNumber: phoneNumber,
                group: subdomain
            }

            console.log(userDetails);

            const newUser = await API.graphql(graphqlOperation(createUser, { input: userDetails })) as { data: any };
            console.log(newUser.data.createUser);

            try {
                console.log(profileName)
                const result = await API.graphql(graphqlOperation(createAndSaveBarcode, { input: profileName }));
                console.log(result);
            } catch (err) {
                console.error('User is not authenticated:', err);
            }

            try {
                const newPayment = await createNewPayment(newUser.data.createUser, selectedSubscription);
                console.log(newPayment);
            } catch (err) {
                console.error('User is not authenticated:', err);
            }

            setName(firstName + ' ' + lastName);
            setTimeout(() => {
                setToastClass('hide');
                setTimeout(() => {
                    handleClose();
                    setName('');
                }, 200);
            }, 1000);
            setButtonClicked(false);

        } catch (error) {
            console.log('Error signing up:', error);
        }
    };

    return (
        isOpen && (
            <>
                {name != '' ? (
                    <ToastNotifications name={name} className={toastClass} />
                ) : (
                    <div className="modal-overlay">
                        <div className="modal">
                            <div className="modal-header">
                                <img src={add_user_icon} alt="Add User Icon" />
                                <h2>{t('add_user')}</h2>
                                <button className="close-button" onClick={handleClose}>
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_387_104)">
                                            <path d="M0.511245 21.2832C0.851517 21.6244 1.29901 21.7947 1.74506 21.7947C2.19136 21.7947 2.63861 21.6244 2.97888 21.2832L11 13.2618L19.0211 21.2832C19.3616 21.6244 19.8089 21.7947 20.2549 21.7947C20.7008 21.7947 21.148 21.6244 21.4888 21.2832C22.1703 20.6017 22.1703 19.4971 21.4888 18.8155L13.4672 10.7944L21.0771 3.1845C21.7588 2.50324 21.7588 1.39813 21.0771 0.716866C20.3958 0.0351262 19.2912 0.0351262 18.6095 0.716866L10.9998 8.32656L3.39031 0.716627C2.70881 0.0348875 1.60394 0.0348875 0.922915 0.716627C0.241176 1.39789 0.241176 2.503 0.922915 3.18426L8.53261 10.7942L0.511722 18.8153C-0.170494 19.4966 -0.170494 20.6012 0.511245 21.2832Z" fill="white" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_387_104">
                                                <rect width="22" height="22" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <Flex direction="column" width="100%">
                                        <TextField
                                            label=""
                                            placeholder={t('name')}
                                            type="text"
                                            onChange={(e) => handleInputChange(e, 'firstName')}
                                        />
                                        {touched.firstName && firstNameError && <div className="error">{firstNameError}</div>}
                                        <TextField
                                            label=""
                                            placeholder={t('surname')}
                                            type="text"
                                            onChange={(e) => handleInputChange(e, 'lastName')}
                                        />
                                        {touched.lastName && lastNameError && <div className="error">{lastNameError}</div>}
                                        <TextField
                                            label=""
                                            placeholder={t('email')}
                                            type="email"
                                            onChange={(e) => handleInputChange(e, 'email')}
                                        />
                                        {touched.email && emailError && <div className="error">{emailError}</div>}
                                        <TextField
                                            label=""
                                            placeholder={t('phone_number')}
                                            type="text"
                                            onChange={(e) => handleInputChange(e, 'phoneNumber')}
                                        />
                                        {touched.phoneNumber && phoneNumberError && <div className="error">{phoneNumberError}</div>}
                                    </Flex>

                                </form>
                                <div className="select-container">
                                    <SelectField label="Dodaj uplatu" onChange={(e) => setSelectedSubscription(e.target.value)}>
                                        <option value='30'>30 {t("days")}</option>
                                        <option value='60'>60 {t("days")}</option>
                                        <option value='90'>90 {t("days")}</option>
                                    </SelectField>
                                </div>
                            </div>
                            <Flex justifyContent="right" style={{ marginTop: '20px' }}>
                                {/* <Button onClick={handleClose}>
                        Odustani
                    </Button> */}
                                <Button type="button" onClick={handleSignUp}
                                    disabled={!firstName || !lastName || !email || phoneNumberError !== ''}
                                    className="primary-btn" style={{ minWidth: '150px', marginTop: '25px' }}>
                                    {buttonClicked ? (
                                        <>
                                            <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" className="loading-svg">
                                                <circle cx="13.5" cy="13.5" r="11" stroke="url(#paint0_linear_369_1384)" strokeWidth="5" />
                                                <defs>
                                                    <linearGradient id="paint0_linear_369_1384" x1="23.5" y1="2.50008e-07" x2="9" y2="27" gradientUnits="userSpaceOnUse">
                                                        <stop stopColor="white" />
                                                        <stop offset="1" stopColor="white" stopOpacity="0" />
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                        </>
                                    ) : (
                                        <span>{t("add")}</span>
                                    )}
                                </Button>
                            </Flex>
                        </div>
                    </div>
                )}
            </>
        )
    );
};

export default FormDialog;
