
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LogoState {
    url: string;
}

const initialState: LogoState = {
    url: '/logo.png',

};

const logoSlice = createSlice({
    name: 'logo',
    initialState,
    reducers: {
        setLogoUrl: (state, action: PayloadAction<string>) => {
            state.url = action.payload;
        },
    },
});

export const { setLogoUrl } = logoSlice.actions;

export default logoSlice.reducer;
