import React, { useEffect, useState } from "react";
import { View, Flex, useTheme } from "@aws-amplify/ui-react";
import ProfileHeader from "./ProfileHeader";
import ProfileInformation from "./ProfileInformation";
import ProfileSettings from "./ProfileSettings";
import "./Profile.css";
// import BarcodeReader from 'react-barcode-reader'
import { Auth } from 'aws-amplify';


const Profile = () => {
  const { tokens } = useTheme();
  // const [code, setCode] = useState('No result');
  const [username, setUsername] = useState('filip+2@digitalartsagency.com');
  const [password, setPassword] = useState('test12345');
  const [email, setEmail] = useState("");

  async function signUp() {
    try {
      const { user } = await Auth.signUp({
        username,
        password,
      });
      console.log(user);
    } catch (error) {
      console.log('error signing up:', error);
    }
  }

  Auth.currentAuthenticatedUser().then(user => {
    setEmail(user.attributes.email);
  }).catch(err => console.log(err));

  // const handleScan = (data) => {
  //   setCode(data);
  // };

  // const handleError = (err) => {
  //   console.error(err);
  // };

  return (
    <>
      {/* <BarcodeReader
          onError={handleError}
          onScan={handleScan}
          /> */}
      {/* <p>{code}</p> */}
      <button onClick={signUp}>
        Click me!
      </button>
      <div>
        <h2>Profile</h2>
      </div>
      <View maxWidth="100%" padding="0rem" minHeight="100vh">
        <Flex
          direction={{ base: "column", large: "row" }}
          alignItems="flex-start"
          gap={tokens.space.xl}
          marginBottom="30px"
        >
          <View
            backgroundColor="var(--amplify-colors-white)"
            borderRadius="6px"
            width={{ base: "100%", large: "100%" }}
            padding="1rem"
          >
            <ProfileHeader email={email}/>
          </View>
        </Flex>

        <Flex
          direction={{ base: "column", large: "row" }}
          gap={tokens.space.xl}
          alignItems="flex-start"
        >
          <View
            backgroundColor="var(--amplify-colors-white)"
            borderRadius="6px"
            width={{ base: "100%", large: "40%" }}
            padding={{ base: "1em", large: "1.5rem" }}
          >
            <ProfileInformation email={email}/>
          </View>
          <View
            backgroundColor="var(--amplify-colors-white)"
            borderRadius="6px"
            width={{ base: "100%", large: "40%" }}
            padding={{ base: "1em", large: "1.5rem" }}
          >
            <ProfileSettings />
          </View>
        </Flex>
      </View>
    </>
  );
};

export default Profile;
