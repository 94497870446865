import React, { useEffect, useState } from "react";
import {
  View,
  Grid,
  Flex,
  Card,
  Placeholder,
  useTheme,
} from "@aws-amplify/ui-react";
import { Amplify } from 'aws-amplify';
import awsconfig from './../../aws-exports';
import MiniStatistics from "./MiniStatistics";
import SalesSummary from "./SalesSummary";
import CustomersSummary from "./CustomersSummary";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { useDispatch } from 'react-redux';
import fetchUsers from '../../store/users/fetchUsers';
import "./Dashboard.css";


Amplify.configure(awsconfig);

/// Mock Data
const barChartDataDemo = [
];

const getChartData = () =>
  new Promise((resolve, reject) => {
    if (!barChartDataDemo) {
      return setTimeout(() => reject(new Error("no data")), 750);
    }

    setTimeout(() => resolve(Object.values(barChartDataDemo)), 750);
  });

const Dashboard = () => {
  const users_count = useSelector((state: RootState) => state.users.users_count);
  const active_users_count = useSelector((state: RootState) => state.users.active_user);
  const inactive_users_count = useSelector((state: RootState) => state.users.inactive_user);
  const new_users_by_month = useSelector((state: RootState) => state.users.new_users_by_month);
  const [barChartData, setBarChartData] = useState<any | null>(null);
  const { tokens } = useTheme();
  const [newUsersData, setNewUsersData] = useState<any | null>(null);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    fetchUsers(dispatch);
  }, []);

  useEffect(() => {
    const doChartData = async () => {
      const result = await getChartData();
      setBarChartData(result);
    };

    setNewUsersData([
      {
        name: t("new_users"),
        data: Object.values(new_users_by_month)
      }
    ]);

    doChartData();
  }, [new_users_by_month]);

  return (
    <>
      <div>
        <h2>Dashboard</h2>
      </div>
      <View borderRadius="6px" maxWidth="100%" padding="0rem" minHeight="100vh">
        <Grid
          templateColumns={{ base: "1fr", large: "1fr 1fr 1fr" }}
          templateRows={{ base: "repeat(4, 10rem)", large: "repeat(3, 8rem)" }}
          gap={tokens.space.xl}
        >
          <View rowSpan={{ base: 1, large: 1 }}>
            <MiniStatistics
              title={t('number_of_users')}
              amount={users_count !== 0 ? users_count.toString() : "Loading..."} // Display user count
              icon={
                <svg width="32" height="27" viewBox="0 0 32 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.3106 4.6342C20.7902 5.56432 21.8277 7.13829 22.0166 8.9653C22.62 9.24798 23.2898 9.41054 23.9998 9.41054C26.592 9.41054 28.693 7.30405 28.693 4.70547C28.693 2.10649 26.592 0 23.9998 0C21.4324 0.000800795 19.3498 2.06965 19.3106 4.6342ZM16.236 14.2686C18.8282 14.2686 20.9292 12.1617 20.9292 9.56309C20.9292 6.96451 18.8278 4.85802 16.236 4.85802C13.6442 4.85802 11.542 6.96491 11.542 9.56349C11.542 12.1621 13.6442 14.2686 16.236 14.2686ZM18.2268 14.5893H14.2445C10.931 14.5893 8.23542 17.2924 8.23542 20.6145V25.4973L8.2478 25.5738L8.58325 25.6791C11.7453 26.6697 14.4924 27 16.7536 27C21.17 27 23.7298 25.7375 23.8876 25.6571L24.2011 25.4981H24.2346V20.6145C24.2358 17.2924 21.5402 14.5893 18.2268 14.5893ZM25.9914 9.73166H22.0398C21.9971 11.3168 21.3222 12.7443 20.2547 13.7709C23.1999 14.6489 25.3548 17.3873 25.3548 20.6217V22.1264C29.2565 21.983 31.5048 20.8743 31.653 20.7998L31.9665 20.6405H32V15.756C32 12.4343 29.3044 9.73166 25.9914 9.73166ZM8.001 9.41134C8.91911 9.41134 9.77332 9.14268 10.4969 8.68502C10.727 7.18073 11.5313 5.86622 12.6802 4.97093C12.685 4.88285 12.6934 4.79556 12.6934 4.70667C12.6934 2.10769 10.592 0.00120111 8.001 0.00120111C5.40841 0.00120111 3.30782 2.10769 3.30782 4.70667C3.30782 7.30445 5.40841 9.41134 8.001 9.41134ZM12.2157 13.7709C11.1535 12.7495 10.481 11.3296 10.4318 9.75408C10.2853 9.74327 10.1403 9.73166 9.99096 9.73166H6.00904C2.69562 9.73166 0 12.4343 0 15.756V20.6397L0.0123799 20.715L0.347835 20.8211C2.88451 21.6151 5.14843 21.981 7.11484 22.0963V20.6217C7.11564 17.3873 9.26974 14.6497 12.2157 13.7709Z" fill="#7C66EB" />
                </svg>
              }
            />
          </View>
          <View rowSpan={{ base: 1, large: 1 }}>
            <MiniStatistics title={t("active_users")} amount={active_users_count !== 0 ? active_users_count.toString() : "Loading..."} icon={
              <svg width="32" height="27" viewBox="0 0 32 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.3106 4.6342C20.7902 5.56432 21.8277 7.13829 22.0166 8.9653C22.62 9.24798 23.2898 9.41054 23.9998 9.41054C26.592 9.41054 28.693 7.30405 28.693 4.70547C28.693 2.10649 26.592 0 23.9998 0C21.4324 0.000800795 19.3498 2.06965 19.3106 4.6342ZM16.236 14.2686C18.8282 14.2686 20.9292 12.1617 20.9292 9.56309C20.9292 6.96451 18.8278 4.85802 16.236 4.85802C13.6442 4.85802 11.542 6.96491 11.542 9.56349C11.542 12.1621 13.6442 14.2686 16.236 14.2686ZM18.2268 14.5893H14.2445C10.931 14.5893 8.23542 17.2924 8.23542 20.6145V25.4973L8.2478 25.5738L8.58325 25.6791C11.7453 26.6697 14.4924 27 16.7536 27C21.17 27 23.7298 25.7375 23.8876 25.6571L24.2011 25.4981H24.2346V20.6145C24.2358 17.2924 21.5402 14.5893 18.2268 14.5893ZM25.9914 9.73166H22.0398C21.9971 11.3168 21.3222 12.7443 20.2547 13.7709C23.1999 14.6489 25.3548 17.3873 25.3548 20.6217V22.1264C29.2565 21.983 31.5048 20.8743 31.653 20.7998L31.9665 20.6405H32V15.756C32 12.4343 29.3044 9.73166 25.9914 9.73166ZM8.001 9.41134C8.91911 9.41134 9.77332 9.14268 10.4969 8.68502C10.727 7.18073 11.5313 5.86622 12.6802 4.97093C12.685 4.88285 12.6934 4.79556 12.6934 4.70667C12.6934 2.10769 10.592 0.00120111 8.001 0.00120111C5.40841 0.00120111 3.30782 2.10769 3.30782 4.70667C3.30782 7.30445 5.40841 9.41134 8.001 9.41134ZM12.2157 13.7709C11.1535 12.7495 10.481 11.3296 10.4318 9.75408C10.2853 9.74327 10.1403 9.73166 9.99096 9.73166H6.00904C2.69562 9.73166 0 12.4343 0 15.756V20.6397L0.0123799 20.715L0.347835 20.8211C2.88451 21.6151 5.14843 21.981 7.11484 22.0963V20.6217C7.11564 17.3873 9.26974 14.6497 12.2157 13.7709Z" fill="#25E572" />
              </svg>
            } />
          </View>
          <View rowSpan={{ base: 1, large: 1 }}>
            <MiniStatistics
              title={t("inactive_users")}
              amount={inactive_users_count !== 0 ? inactive_users_count.toString() : "Loading..."}
              icon={
                <svg width="32" height="27" viewBox="0 0 32 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.3106 4.6342C20.7902 5.56432 21.8277 7.13829 22.0166 8.9653C22.62 9.24798 23.2898 9.41054 23.9998 9.41054C26.592 9.41054 28.693 7.30405 28.693 4.70547C28.693 2.10649 26.592 0 23.9998 0C21.4324 0.000800795 19.3498 2.06965 19.3106 4.6342ZM16.236 14.2686C18.8282 14.2686 20.9292 12.1617 20.9292 9.56309C20.9292 6.96451 18.8278 4.85802 16.236 4.85802C13.6442 4.85802 11.542 6.96491 11.542 9.56349C11.542 12.1621 13.6442 14.2686 16.236 14.2686ZM18.2268 14.5893H14.2445C10.931 14.5893 8.23542 17.2924 8.23542 20.6145V25.4973L8.2478 25.5738L8.58325 25.6791C11.7453 26.6697 14.4924 27 16.7536 27C21.17 27 23.7298 25.7375 23.8876 25.6571L24.2011 25.4981H24.2346V20.6145C24.2358 17.2924 21.5402 14.5893 18.2268 14.5893ZM25.9914 9.73166H22.0398C21.9971 11.3168 21.3222 12.7443 20.2547 13.7709C23.1999 14.6489 25.3548 17.3873 25.3548 20.6217V22.1264C29.2565 21.983 31.5048 20.8743 31.653 20.7998L31.9665 20.6405H32V15.756C32 12.4343 29.3044 9.73166 25.9914 9.73166ZM8.001 9.41134C8.91911 9.41134 9.77332 9.14268 10.4969 8.68502C10.727 7.18073 11.5313 5.86622 12.6802 4.97093C12.685 4.88285 12.6934 4.79556 12.6934 4.70667C12.6934 2.10769 10.592 0.00120111 8.001 0.00120111C5.40841 0.00120111 3.30782 2.10769 3.30782 4.70667C3.30782 7.30445 5.40841 9.41134 8.001 9.41134ZM12.2157 13.7709C11.1535 12.7495 10.481 11.3296 10.4318 9.75408C10.2853 9.74327 10.1403 9.73166 9.99096 9.73166H6.00904C2.69562 9.73166 0 12.4343 0 15.756V20.6397L0.0123799 20.715L0.347835 20.8211C2.88451 21.6151 5.14843 21.981 7.11484 22.0963V20.6217C7.11564 17.3873 9.26974 14.6497 12.2157 13.7709Z" fill="#E53725" />
                </svg>
              }
            />
          </View>


          <View columnSpan={[1, 1, 1, 2]} rowSpan={{ base: 3, large: 4 }}>
            <Card borderRadius="15px">
              <div className="card-title">{t("payments_statistics")}</div>
              <div className="chart-wrap">
                {barChartData ? (
                  <div className="row">
                    <SalesSummary
                      title="Sales Summary"
                      type="line"
                      labels={[
                        "Jan",
                        "Feb",
                        "Mar",
                        "Apr",
                        "May",
                        "Jun",
                        "Jul",
                        "Aug",
                        "Sep",
                        "Oct",
                        "Nov",
                        "Dec",
                      ]}
                    />
                  </div>
                ) : (
                  <Flex direction="column" minHeight="285px">
                    <Placeholder size="small" />
                    <Placeholder size="small" />
                    <Placeholder size="small" />
                    <Placeholder size="small" />
                  </Flex>
                )}
              </div>
            </Card>
          </View>

          <View rowSpan={{ base: 1, large: 4 }}>
            <Card height="100%" borderRadius="15px">
              <div className="card-title">{t("new_users")}</div>
              <div className="chart-wrap">
                {barChartData ? (
                  <div className="row">
                    {newUsersData && newUsersData.length > 0 ? (
                      <CustomersSummary
                        title="CustomersSummary"
                        data={newUsersData}
                        type="line"
                        labels={Object.keys(new_users_by_month)}
                      />
                    ) : (
                      /* Placeholder or alternative content here */
                      null
                    )}



                  </div>
                ) : (
                  <Flex direction="column" minHeight="285px">
                    <Placeholder size="small" />
                    <Placeholder size="small" />
                    <Placeholder size="small" />
                    <Placeholder size="small" />
                  </Flex>
                )}
              </div>
            </Card>
          </View>
        </Grid>
      </View>
    </>
  );
};

export default Dashboard;
