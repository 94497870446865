
export const createProgressPayments = /* GraphQL */ `
  mutation CreateProgressPayments(
    $input: CreateProgressPaymentsInput!
    $condition: ModelProgressPaymentsConditionInput
  ) {
    createProgressPayments(input: $input, condition: $condition) {
      id
      userID
      adminGroupName
      subscriptionType
      startDate
      endDate
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const updateProgressPayments = /* GraphQL */ `
  mutation UpdateProgressPayments(
    $input: UpdateProgressPaymentsInput!
    $condition: ModelProgressPaymentsConditionInput
  ) {
    updateProgressPayments(input: $input, condition: $condition) {
      id
      userID
      subscriptionType
      startDate
      endDate
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const deleteProgressPayments = /* GraphQL */ `
  mutation DeleteProgressPayments(
    $input: DeleteProgressPaymentsInput!
    $condition: ModelProgressPaymentsConditionInput
  ) {
    deleteProgressPayments(input: $input, condition: $condition) {
      id
      userID
      subscriptionType
      startDate
      endDate
      isActive
      createdAt
      updatedAt
    }
  }
`;

export const createAppSettings = /* GraphQL */ `
  mutation CreateAppSettings($input: CreateAppSettingsInput!) {
    createAppSettings(input: $input) {
      id
      adminGroupName
      scanType
      themeSettings {
        id
        primaryColor
        secondaryColor
        backgroundColor
        logo
        defaultLanguage
        languages
      }
    }
  }
`;

export const updateAppSettings = /* GraphQL */ `
  mutation UpdateAppSettings($input: UpdateAppSettingsInput!) {
    updateAppSettings(input: $input) {
      id
      adminGroupName
      scanType
      themeSettings {
        id
        primaryColor
        secondaryColor
        backgroundColor
        logo
        defaultLanguage
        languages
      }
    }
  }
`;

export const createProgressUserHistory = /* GraphQL */ `
  mutation CreateProgressUserHistory($input: CreateProgressUserHistoryInput!) {
    createProgressUserHistory(input: $input) {
      id
      userID
      adminGroupName
      lastVisited
    }
  }
`;

export const updateProgressUserHistory = /* GraphQL */ `
  mutation UpdateProgressUserHistory($input: UpdateProgressUserHistoryInput!) {
    updateProgressUserHistory(input: $input) {
      id
      userID
      adminGroupName
      lastVisited
    }
  }
`;

export const deleteProgressUserHistory = /* GraphQL */ `
  mutation DeleteProgressUserHistory($input: DeleteProgressUserHistoryInput!) {
    deleteProgressUserHistory(input: $input) {
      id
    }
  }
`;



