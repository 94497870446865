import React, { useState, useEffect } from 'react';
import { SelectField } from '@aws-amplify/ui-react';
import { I18n } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/store';
import { setSelectedLanguage } from '../../store/language/languageSlice';

function LanguageSelector() {
    const dispatch = useDispatch();
    const [globalLanguage, setGlobalLanguage] = useState("sr");
    const { i18n } = useTranslation();
    const languages = useSelector((state: RootState) => state.language.languagesOrder);

    useEffect(() => {
        i18n.changeLanguage(globalLanguage);
        I18n.setLanguage(globalLanguage);
        dispatch(setSelectedLanguage(globalLanguage)); // Dispatch action to update selectedLanguage in Redux
    }, [globalLanguage, i18n, dispatch]);

    return (
        <SelectField
            label="Choose Language"
            labelHidden={true}
            onChange={(event) => setGlobalLanguage(event.target.value)}
            value={globalLanguage}
            style={{ background: 'white', color: 'black', zIndex: 1000, position: 'absolute', top: '10px', left: '91%', margin: '0', right: '0px', width: '10px', display: 'none' }}
        >
            {Object.entries(languages).map(([lang, isChecked]) =>
                isChecked && <option key={lang} value={lang}>{lang.toUpperCase()}</option>
            )}
        </SelectField>
    );
}

export default LanguageSelector;
