import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { API, graphqlOperation } from 'aws-amplify';
import { listProgressPayments } from '../../graphql/queries'; // Import your query
import { useTranslation } from "react-i18next";

const defaultOptions = {
  toolbar: {
    show: false,
  },
  chart: {
    toolbar: {
      show: false,
    },
  },
  colors: ["#5f71e4", "#2dce88"],
  legend: {
    show: false,
  },
  stroke: {
    curve: "smooth",
  },
};

interface SalesSummaryProps {
  title?: string;
  type?:
  | "line"
  | "area"
  | "bar"
  | "histogram"
  | "pie"
  | "donut"
  | "radialBar"
  | "scatter"
  | "bubble"
  | "heatmap"
  | "treemap"
  | "boxPlot"
  | "candlestick"
  | "radar"
  | "polarArea"
  | "rangeBar";
  options?: any;
  labels?: string[];
}


const SalesSummary = ({ type, labels, options }: SalesSummaryProps) => {
  const [data, setData] = useState<any[]>([]); // Initialize state
  const createdDateList = [] as any[];
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const paymentsResult = await API.graphql(graphqlOperation(listProgressPayments, { /* your variables here */ })) as { data: any };

        const items = paymentsResult.data.listProgressPayments.items;

        for (const item of items) {
          createdDateList.push(item.createdAt);
        }

        // console.log(createdDateList);

        // Initialize an empty object to hold the counts
        const countsByMonth: { [key: string]: number } = {};

        // Loop through each item and increment the count for its month
        items.forEach(item => {
          const date = new Date(item.createdAt);
          const month = date.getMonth() + 1;  // JavaScript months are 0-indexed
          const year = date.getFullYear();
          const key = `${year}-${month < 10 ? '0' + month : month}`;

          countsByMonth[key] = (countsByMonth[key] || 0) + 1;
        });

        // Transform the counts into an array suitable for your chart
        const chartData = Object.keys(countsByMonth).map(key => ({
          x: key,
          y: countsByMonth[key]
        }));

        // Now, chartData is an array of { x: 'YYYY-MM', y: count } objects
        setData(chartData);

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Fetch data when component mounts
  }, []); // Empty dependency array means this useEffect runs once when component mounts

  return (
    <>
      <Chart
        series={[{ name: t("number_of_payments"), data }]} // Use state data here
        type={type as any}
        height={300}
        options={Object.assign({}, defaultOptions, options, { labels })}
      ></Chart>
    </>
  );
};

export default SalesSummary;
