import React, { useEffect, useState } from 'react';
import { API, Amplify, graphqlOperation } from 'aws-amplify';
import awsconfig from './../../aws-exports';
import { Table, TableCell, TableBody, TableHead, TableRow, Button } from '@aws-amplify/ui-react';
import SubscriptionDialog from '../dialog/Subscription';
import { createProgressPayments } from './../../graphql/mutations'; // Import the createProgressPayments mutation
import { getProgressPaymentsByUserId } from '../../graphql/custom-queries';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { useDispatch } from 'react-redux';
import fetchUsers from '../../store/users/fetchUsers';
import FormDialog from '../dialog/FormDialog'; // Import MyDialog component

Amplify.configure(awsconfig);

interface User {
  Username: string;
  Name: string;
  Surname: string;
  Email: string;
  PhoneNumber: string;
  EndDate: string;
}

const BasicTable = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);


  const handleOpenDialog = () => {
    setIsDialogOpen(true);
    console.log('Dialog opened');
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };
  const users = useSelector((state: RootState) => state.users.users);
  const [isSubscriptionDialogOpen, setSubscriptionDialogOpen] = useState(false);
  const [userToSubscribe, setUserToSubscribe] = useState<User | null>(null);
  const subdomain = useSelector((state: RootState) => state.subdomain.subdomain);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchUsers(dispatch);
  }, []);

  function getDate(dateTime) {
    const date = new Date(dateTime);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  async function createNewPayment(userId, subscriptionType) {
    let startDate;
    let endDate;

    //Get last user payments
    const userPayments = await API.graphql(graphqlOperation(getProgressPaymentsByUserId, { userID: userId })) as { data: any };

    if (userPayments.data.progressPaymentsByUserId.items.length > 0) {
      startDate = userPayments.data.progressPaymentsByUserId.items[0].endDate;

      let endDateString = userPayments.data.progressPaymentsByUserId.items[0].endDate;
      let endDateArray = endDateString.split('-');

      endDate = new Date(endDateArray[2], endDateArray[1] - 1, endDateArray[0]);

      if (endDate.getTime() < new Date().getTime()) {
        startDate = getDate(new Date());
        endDate = new Date();
      }
    } else {
      startDate = getDate(new Date());
      endDate = new Date();
    }

    // Determine the number of days to add based on subscriptionType
    let daysToAdd = 0;
    if (subscriptionType === '30') {
      daysToAdd = 30;
    } else if (subscriptionType === '60') {
      daysToAdd = 60;
    } else if (subscriptionType === '90') {
      daysToAdd = 90;
    }
    endDate.setDate(endDate.getDate() + daysToAdd);
    const endDateStr = getDate(endDate);
    // Calculate the expireAt field as 4 months from now in Unix epoch time (seconds)
    const fourMonthsInMilliseconds = 4 * 30 * 24 * 60 * 60 * 1000; // approximately 4 months
    const expireAt = Date.now() + fourMonthsInMilliseconds;

    const newPayment = {
      userID: userId,
      adminGroupName: subdomain,
      subscriptionType,
      startDate,
      endDate: endDateStr,
      isActive: true,
      expireAt,
    };

    try {
      const result = await API.graphql(graphqlOperation(createProgressPayments, { input: newPayment }));
      console.log('New payment created:', result);
    } catch (error) {
      console.error('Error creating new payment:', error);
    }
  }

  const openSubscriptionDialog = (user) => {
    setUserToSubscribe(user);
    setSubscriptionDialogOpen(true);
  };


  const closeSubscriptionDialog = () => {
    setSubscriptionDialogOpen(false);
  };

  const handleSubscription = async (subscriptionType) => {
    if (userToSubscribe) {
      const userId = userToSubscribe.Username;
      console.log(userToSubscribe);
      console.log(userId);
      await createNewPayment(userId, subscriptionType);
      // Close the dialog after the payment is made
      closeSubscriptionDialog();
    }

    // Refresh the users list
    fetchUsers(dispatch);

  };


  return (
    <>
      <SubscriptionDialog
        isOpen={isSubscriptionDialogOpen}
        handleClose={closeSubscriptionDialog}
        handleSubscription={handleSubscription}
      />
      <Button
        style={{
          backgroundColor: '#7C66EB',
          color: 'white',
          padding: '10px 16px',
          margin: 'auto',
          right: '30px',
          borderRadius: '10px',
          boxShadow: '0px 4px 6px 0px #00000026',
          border: 'none',
          fontSize: '18px',
          top: '60px',
          position: 'absolute'
        }}
        onClick={handleOpenDialog}
        className="primary-btn"
      >
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" style={{
          marginRight: '10px'
        }}>
          <rect x="7" width="3" height="17" rx="1.5" fill="white" />
          <rect y="10" width="3" height="17" rx="1.5" transform="rotate(-90 0 10)" fill="white" />
        </svg>
        {t('add_user')}
      </Button>

      <Table caption="" highlightOnHover={false}>
        <TableHead>
          <TableRow>
            <TableCell as="th">{t('name_and_surname')}</TableCell>
            <TableCell as="th">{t('email')}</TableCell>
            <TableCell as="th">{t('phone_number')}</TableCell>
            <TableCell as="th">{t('active_subscription_until')}</TableCell>
            <TableCell as="th">{t('action')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user, index) => {
            let color: string = '#FFFFFF';
            if (user.EndDate) {
              const dateParts = user.EndDate.split('-');
              const endDate = new Date(parseInt(dateParts[2], 10), parseInt(dateParts[1], 10) - 1, parseInt(dateParts[0], 10));

              const currentDate = new Date();
              const fiveDaysInMilliseconds = 5 * 24 * 60 * 60 * 1000;
              const isWithinFiveDays = endDate && endDate.getTime() - currentDate.getTime() <= fiveDaysInMilliseconds;
              const hasPassed = endDate && endDate.getTime() < currentDate.getTime();

              if (hasPassed) {
                color = '#E53725';
              } else if (isWithinFiveDays) {
                color = '#dda63b';
              } else {
                color = '#25E572';
              }
            }

            return (
              <TableRow key={index}>
                <TableCell>{user.Name} {user.Surname}</TableCell>
                <TableCell>{user.Email}</TableCell>
                <TableCell>{user.PhoneNumber}</TableCell>
                <TableCell style={{ color: color }}>{user.EndDate || ''}</TableCell>
                <TableCell>
                  <Button onClick={() => openSubscriptionDialog(user)} className="billing-btn">
                    {t('payment')}
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <FormDialog isOpen={isDialogOpen} handleClose={handleCloseDialog} />
    </>
  );
};

export default BasicTable;
