import React from 'react';
import {
    Button,
    Flex,
    Text,
} from "@aws-amplify/ui-react";
import './Dialog.css';
import { useTranslation } from 'react-i18next';

// Add name, surname, and isUserEnabled as props
const ConfirmDialog = ({ isOpen, handleClose, handleLogout }) => {
    const { t } = useTranslation();

    return (
        isOpen && (
            <div className="modal-overlay">
                <div className="modal">
                    <div className="modal-header">
                        <h2>{t("notification")}</h2>
                        <button className="close-button" onClick={handleClose}>                     
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_387_104)">
                            <path d="M0.511245 21.2832C0.851517 21.6244 1.29901 21.7947 1.74506 21.7947C2.19136 21.7947 2.63861 21.6244 2.97888 21.2832L11 13.2618L19.0211 21.2832C19.3616 21.6244 19.8089 21.7947 20.2549 21.7947C20.7008 21.7947 21.148 21.6244 21.4888 21.2832C22.1703 20.6017 22.1703 19.4971 21.4888 18.8155L13.4672 10.7944L21.0771 3.1845C21.7588 2.50324 21.7588 1.39813 21.0771 0.716866C20.3958 0.0351262 19.2912 0.0351262 18.6095 0.716866L10.9998 8.32656L3.39031 0.716627C2.70881 0.0348875 1.60394 0.0348875 0.922915 0.716627C0.241176 1.39789 0.241176 2.503 0.922915 3.18426L8.53261 10.7942L0.511722 18.8153C-0.170494 19.4966 -0.170494 20.6012 0.511245 21.2832Z" fill="white"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_387_104">
                            <rect width="22" height="22" fill="white"/>
                            </clipPath>
                            </defs>
                        </svg>
                        </button>
                    </div>
                    <div className="modal-body">
                        {/* Use name and surname in the dialog */}
                        <Text>{t("are_you_sure_you_want_to_log_out")}</Text>
                    </div>
                    <Flex justifyContent="right" style={{ marginTop: '20px' }}>
                        {/* Conditionally set the button text */}
                        <Button type="button" onClick={handleLogout} className="primary-btn" style={{ minWidth: '150px', marginTop: '25px'}}>
                            {t("log_out")}
                        </Button>
                    </Flex>
                </div>
            </div>
        )
    );
};

export default ConfirmDialog;
