// Tables.js
import React, { useState } from 'react';
import { View, ScrollView } from '@aws-amplify/ui-react';
import BasicTable from './BasicTable';
import FormDialog from '../dialog/FormDialog'; // Import MyDialog component
import './Tables.css';
import { useTranslation } from 'react-i18next';

const Tables = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { t } = useTranslation();

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <div className="basic-table-header">
        <h2>{t('users')}</h2>
      </div>

      <View
        backgroundColor="#121212"
        borderRadius="6px"
        maxWidth="100%"
        padding="1rem"
        minHeight="80vh"
        boxShadow={"0px 3px 11px 0px #00000040"}
      >
        <br />
        <ScrollView width="100%">
          <BasicTable />
        </ScrollView>
      </View>

      <FormDialog isOpen={isDialogOpen} handleClose={handleCloseDialog} />
    </>
  );
};

export default Tables;
